export const useContentLink = (e: Event) => {
  const router = useRouter();
  const localePath = useLocalePathPolyfill();

  const link = (e.target as HTMLElement).closest("a")?.href;
  if (link) {
    const url = new URL(link);
    if (window.location.host === url.host && !url.hash) {
      e.preventDefault();
      router.push(localePath(url.pathname + url.search));
    }
  }
};
