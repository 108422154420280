import FingerprintJS from "@fingerprintjs/fingerprintjs";

export default defineNuxtPlugin(async () => {
  try {
    const fpPromise = FingerprintJS.load();

    async function generateUniqueId(): Promise<string> {
      const fp = await fpPromise;
      const result = await fp.get();
      return result.visitorId;
    }

    const { uidCookie } = useCookiesService();

    if (!uidCookie.value) {
      uidCookie.value = await generateUniqueId();
    }
  } catch (error) {
    console.error("Failed to generate unique ID using FingerprintJS:", error);
  }
});
