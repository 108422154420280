import FetchFactory from "../factory";
import { PageResponse } from "~/types/api/pages.types";
import { BlockResponse } from "@/types/api/block.types";

class PagesModule extends FetchFactory {
  /**
   * Get page by id or slug
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/#api-Page-GetApiV1PagesPageid
   * @returns Promise
   */
  getPage(id: string | number) {
    return this.fetch<PageResponse>("GET", `/pages/${id}`, {});
  }

  /**
   * Get block
   *
   * @link https://dev-opr.diamondnexus.com/apidoc/#api-Blockembedded_code-GetApiV1BlocksBlockname
   * @param data
   * @returns Promise
   */
  getBlok(slug: string) {
    return this.fetch<BlockResponse>("GET", `/blocks/${slug}`, {});
  }
}

export default PagesModule;
