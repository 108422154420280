import {
  type $Fetch,
  type NitroFetchOptions,
  type NitroFetchRequest,
} from "nitropack";
import { hash } from "ohash";

/*
 The FetchFactory acts as a wrapper around an HTTP client.
 It encapsulates the functionality for making API requests asynchronously
 through the call function, utilizing the provided HTTP client.
*/
export function generateOptionSegments(
  opts: NitroFetchOptions<NitroFetchRequest>,
) {
  const segments: Array<string | undefined | Record<string, string>> = [
    toValue(
      opts.method as MaybeRef<string | undefined> | undefined,
    )?.toUpperCase() || "GET",
  ];
  for (const _obj of [opts.params || opts.query]) {
    const obj = toValue(_obj);
    if (!obj) {
      continue;
    }

    const unwrapped: Record<string, string> = {};
    for (const [key, value] of Object.entries(obj)) {
      unwrapped[toValue(key)] = toValue(value);
    }
    segments.push(unwrapped);
  }
  return segments;
}

class FetchFactory {
  private readonly $fetch: $Fetch;
  private controllers: Map<string, AbortController>;
  constructor(fetcher: $Fetch<unknown, NitroFetchRequest>) {
    this.$fetch = fetcher;
    this.controllers = new Map();
  }

  /**
   * The HTTP client is utilized to control the process of making API requests.
   * @param method the HTTP method (GET, POST, ...)
   * @param url the endpoint url
   * @param fetchOptions fetch options
   * @returns
   */

  fetch<D>(
    method: NitroFetchOptions<NitroFetchRequest>["method"],
    url: string,
    fetchOptions?: NitroFetchOptions<NitroFetchRequest>,
  ) {
    const key = hash([
      fetchOptions?.key,
      url,
      ...generateOptionSegments(fetchOptions || {}),
    ]);

    this.controllers
      .get(key)
      ?.abort?.(
        "Request aborted as another request to the same endpoint was initiated.",
      );
    this.controllers.set(
      key,
      typeof AbortController !== "undefined"
        ? new AbortController()
        : ({} as AbortController),
    );

    return this.$fetch<D>(url, {
      method,
      signal: this.controllers.get(key)!.signal,
      ...fetchOptions,
    });
  }
}

export default FetchFactory;
