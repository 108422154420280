import FetchFactory from "../factory";
import { MaterialsResponse } from "~/types/api/materials.types";

class MaterialsModule extends FetchFactory {
  /**
   * Get materials
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/#api-Materials-GetApiV1Materials
   * @returns Promise
   */
  getMaterials() {
    return this.fetch<MaterialsResponse>("GET", "/materials", {});
  }
}

export default MaterialsModule;
