import FetchFactory from "../factory";
import {
  CollectionResponse,
  CollectionsResponse,
} from "~/types/api/collections.types";

class CollectionsModule extends FetchFactory {
  /**
   * Get collection by slug
   *
   * @link https://dev-opr.diamondnexus.com/apidoc/#api-Collections-GetApiV1CollectionsCollectionslug
   * @returns Promise
   * @param slug
   */
  getCollectionBySlug(slug: string) {
    return this.fetch<CollectionResponse>("GET", `/collections/${slug}`, {});
  }

  /**
   * Get collections
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/#api-Collections-GetApiV1Collections
   * @returns Promise
   */
  getCollections() {
    return this.fetch<CollectionsResponse>("GET", "/collections", {});
  }
}

export default CollectionsModule;
