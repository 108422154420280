<template>
  <component
    :is="to ? NuxtLink : 'button'"
    :type="type"
    v-bind="bindProps"
    :class="
      cn(buttonVariants({ variant, size, color, variantType }), props.class)
    "
  >
    <span
      v-if="loading"
      class="absolute inset-0 flex items-center justify-center"
    >
      <UiSpinner class="h-5 w-5 animate-spin" />
    </span>
    <span
      :class="cn('inline-flex items-center', contentClass)"
      :style="{ opacity: loading ? '0' : '' }"
    >
      <slot />
    </span>
  </component>
</template>

<script setup lang="ts">
import type { HTMLAttributes } from "vue";
import type { RouteLocationRaw } from "vue-router";
import { type ButtonVariants, buttonVariants } from "./variant";
import { NuxtLink } from "#components";
import { cn } from "~/utils";

interface BaseLink {
  to?: RouteLocationRaw;
  disabled?: boolean;
  loading?: boolean;
  variant?: ButtonVariants["variant"];
  size?: ButtonVariants["size"];
  color?: ButtonVariants["color"];
  variantType?: ButtonVariants["variantType"];
  class?: HTMLAttributes["class"];
  contentClass?: HTMLAttributes["class"];
  type?: HTMLButtonElement["type"];
}

const props = withDefaults(defineProps<BaseLink>(), {
  to: undefined,
  type: "button",
});

const bindProps = computed(() => {
  let toProps = {};
  if (props.to && !(props.disabled || props.loading)) {
    toProps = { to: props.to };
  }

  let disabledProps = {};
  if (props.disabled || props.loading) {
    disabledProps = { disabled: props.disabled || props.loading };
  }

  return { ...toProps, ...disabledProps };
});
</script>
