import FetchFactory from "../factory";
import { BlockResponse } from "~/types/api/block.types";

class BlocksModule extends FetchFactory {
  /**
   * Get block
   *
   * @link https://dev-opr.diamondnexus.com/apidoc/#api-Blockembedded_code-GetApiV1BlocksBlockname
   * @returns Promise
   * @param slug
   */
  getBlock(slug: string) {
    return this.fetch<BlockResponse>("GET", `/blocks/${slug}`, {});
  }
}

export default BlocksModule;
