import { SearchParameters } from "ofetch";
import FetchFactory from "../factory";
import { EntitiesResponse } from "~/types/api/entities.types";

class EntitiesModule extends FetchFactory {
  /**
   * Get entities
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/#api-SystemEntity-GetApiV1SystemEntitiesEntitytype
   * @returns Promise
   */
  getEntities(entityType: string, params?: SearchParameters) {
    return this.fetch<EntitiesResponse>(
      "GET",
      `/system-entities/${entityType}`,
      { params },
    );
  }
}

export default EntitiesModule;
