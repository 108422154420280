import { useGeneralStore } from "~/stores/general";
import { IntegrationsType } from "~/types/api/bootstrap.types";
import { EventsParams } from "~/types/api/events.types";

export const useOperateStatisticsEvent = (payload: EventsParams) => {
  const generalStore = useGeneralStore();
  const isEventEnabledOperateStatistics =
    generalStore.bootstrap?.integrations?.[
      IntegrationsType.TYPE_OPERATE_STATISTICS
    ]?.events_enabled;
  const domain =
    generalStore.bootstrap?.integrations?.[
      IntegrationsType.TYPE_OPERATE_STATISTICS
    ]?.base_url;
  const apiKey =
    generalStore.bootstrap?.integrations?.[
      IntegrationsType.TYPE_OPERATE_STATISTICS
    ]?.api_key;

  if (!isEventEnabledOperateStatistics || !domain || !apiKey) return;
  useSendEventToSC(payload, domain, apiKey);
};

export const useSendEventToSC = (
  payload: EventsParams,
  domain: string,
  apiKey: string,
) => {
  const { $api } = useNuxtApp();

  $api.events.sendEventToSC(payload, domain, apiKey).catch((error) => {
    console.error("Error sending OperateStatisticsEvent:", error);
  });
};
