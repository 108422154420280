export const EventGender = {
  NotSure: 0,
  Male: 1,
  Female: 2,
} as const;

export interface EventsParams {
  uid: string;
  user_id: string | number | null;
  user_email: string | null;
  user_phone: string | null;
  url: string;
  event_id: string;
  weight: string;
  sex: (typeof EventGender)[keyof typeof EventGender] | null;
  age: string | null;
  tag: string;
  product_id: string | number;
  configuration_id: string | number | undefined;
  search_query: string;
  scroll_depth: string;
  collection_id: string | number | undefined;
  product_sku: string;
  product_price: string | number | undefined;
  order_id: string | number;
  cart_id: string | null;
  checkout_id: string | null;
  message: string;
  referrer: string;
  language: string;
  is_plp?: boolean;
}

export interface EventsPayload {
  type: string;
  params: EventsParams[];
}

export interface CartEvent {
  product_id: string | number;
  configuration_id: string;
  collection_id: string | number;
  product_sku: string;
  product_price: string | number;
}

export interface PurchaseEvent {
  purchase_user_email: string;
  purchase_user_phone: string;
  order_id: string | number;
}

export interface FavoritesEvent {
  product_id: string | number;
  configuration_id: string | undefined;
  product_sku: string;
  product_price: string | number | undefined;
  collection_id?: string | number;
  product_title?: string;
}

export interface AddShippingInfoEvent {
  shipping_email: string;
  shipping_phone: string;
}
