export default defineNuxtRouteMiddleware((to, from) => {
  if ((/\/{2,}/.test(to.path) || /\/$/.test(to.path)) && to.path !== "/") {
    let cleanedUrl = to.path.replace(/\/+/g, "/"); // clear duplicates

    if (cleanedUrl !== "/") {
      cleanedUrl = cleanedUrl.replace(/\/+$/, ""); // clear last '/'
    }

    return navigateTo({ path: cleanedUrl, query: to.query });
  }
});
