import FetchFactory from "../factory";
import {
  ShippingMethodsResponse,
  OrderResponse,
  OrdersResponse,
} from "@/types/api/orders.types";

class OrdersModule extends FetchFactory {
  /**
   * Get Shipping Methods
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/#api-Orders-PostApiV1OrdersRates
   * @param data
   */
  getShippingMethods(data: { cart_unique_id: string }) {
    return this.fetch<ShippingMethodsResponse>("POST", "/orders/rates", {
      body: data,
    });
  }

  /**
   * Get Single Order
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/#api-Orders-GetApiV1OrdersIdNumber0
   * @param id
   */
  getOrderByID(id: string) {
    return this.fetch<OrderResponse>("GET", `/orders/${id}`, {});
  }

  /**
   * Order Tracking
   *
   * @link https://dev-opr.diamondnexus.com/apidoc/#api-Orders-GetApiV1Tracking
   * @param params
   */
  getTrackingOrder(params: {
    email: string;
    number: string;
    billing_zip?: string;
  }) {
    return this.fetch<OrderResponse>("GET", "/tracking", {
      params,
      skipErrors: [404],
    });
  }

  /**
   * Get Orders
   */
  getOrders(params: { page: number; count: number }) {
    return this.fetch<OrdersResponse>("GET", `/orders`, { params });
  }
}

export default OrdersModule;
