import FetchFactory from "../factory";
import { CategoriesResponse } from "~/types/api/categories.types";

class CategoriesModule extends FetchFactory {
  /**
   * Get categories
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/#api-Public-GetApiV1Categories
   * @returns Promise
   */
  getCategories() {
    return this.fetch<CategoriesResponse>("GET", "/categories", {});
  }
}

export default CategoriesModule;
