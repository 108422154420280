import FetchFactory from "../factory";
import { FilterOrderBy } from "~/types/api/helper.types";
import { CatalogResponse } from "~/types/api/catalog.types";
import { ProductResponse, VariantResponse } from "~/types/api/product.types";
import { RangesResponse } from "~/types/api/ranges.types";

export interface GetCatalogParams {
  page?: string | number;
  count?: string | number;
  search?: string;
  categories_sku?: string[];
  collections: number[];
  materials_sku?: string[];
  column?: string;
  order_by?: FilterOrderBy;
}

class ProductsModule extends FetchFactory {
  /**
   * Get product
   *
   * @link https://dev-opr.diamondnexus.com/apidoc/#api-Products-GetApiV1ProductsProductid
   * @param id
   * @returns Promise
   */
  getProductByIdOrSlug(id: string | number) {
    return this.fetch<ProductResponse>("GET", `/products/${id}`, {
      key: `product-${id}`,
    });
  }

  /**
   * Get catalog
   *
   * @link https://dev-opr.diamondnexus.com/apidoc/#api-Products-GetApiV1Catalog
   * @param params
   * @param slug
   * @returns Promise
   */
  getCatalog(
    params: any,
    options?: {
      slug?: string;
      lazy?: boolean;
    },
  ) {
    return this.fetch<CatalogResponse>(
      "GET",
      `/system-catalog${options?.slug ? "/" + options.slug : ""}`,
      {
        params: unref(params),
        key: params.value.search,
        lazy: options?.lazy || false,
      },
    );
  }

  /**
   * Get catalog
   *
   * @link https://dev-opr.diamondnexus.com/apidoc/#api-Products-GetApiV1Catalog
   * @param params
   * @param slug
   * @returns Promise
   */
  getCatalogNoRef(params: any, slug?: string) {
    return this.fetch<CatalogResponse>(
      "GET",
      `/system-catalog${slug ? "/" + slug : ""}`,
      {
        params,
      },
    );
  }

  /**
   * Get catalog ranges
   *
   * @link https://dev-opr.diamondnexus.com/apidoc/#api-Products-GetApiV1Catalog
   * @param params
   * @returns Promise
   */
  getCatalogRanges(params: any) {
    return this.fetch<RangesResponse>("GET", `/system-catalog/ranges`, {
      params,
    });
  }

  /**
   * Get configuration
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/#api-Products-GetApiV1ProductConfigurationsConfigurationid
   * @returns Promise
   * @param configurationId
   * @param options
   */
  getConfiguration(configurationId: Ref<string> | string) {
    return this.fetch<VariantResponse>(
      "GET",
      `/product-configurations/${unref(configurationId)}`,
      {
        key: unref(configurationId),
        skipErrors: [404],
      },
    );
  }

  /**
   * Send config link to email
   *
   * @link https://dev-opr.diamondnexus.com/apidoc/#api-Products-GetApiV1Catalog
   * @param params
   * @returns Promise
   */
  productLinkToEmail(params: FormData) {
    return this.fetch("POST", `/products/link-to-email`, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: params,
    });
  }
}

export default ProductsModule;
