import { SearchParameters } from "ofetch";
import FetchFactory from "../factory";
import { CatalogResponse } from "~/types/api/catalog.types";

class CatalogModule extends FetchFactory {
  /**
   * Get catalog
   *
   * @link https://dev-opr.diamondnexus.com/apidoc/#api-Products-GetApiV1Catalog
   * @param params
   * @param slug
   * @returns Promise
   */
  getCatalog(params: SearchParameters, slug?: string) {
    return this.fetch<CatalogResponse>(
      "GET",
      `/catalog${slug ? "/" + slug : ""}`,
      { params },
    );
  }
}

export default CatalogModule;
