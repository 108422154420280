import FetchFactory from "../factory";
import {
  SignInForm,
  SignUpForm,
  SignInResponse,
  SignUpResponse,
} from "@/types/api/auth.types";

class AuthModule extends FetchFactory {
  /**
   * Lоgin
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/#api-User-PostApiV1Authorizations
   * @param data
   * @returns Promise
   */
  signIn(data: SignInForm) {
    return this.fetch<SignInResponse>("POST", "/authorizations", {
      body: data,
    });
  }

  /**
   * Register
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/#api-User-PostApiV1AuthorizationsSignUp
   * @param data
   * @returns Promise
   */
  signUp(data: SignUpForm) {
    return this.fetch<SignUpResponse>("POST", "/authorizations/sign-up", {
      body: data,
    });
  }

  /**
   * Logout
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/#api-User-DeleteApiV1Authorizations
   * @returns Promise
   */
  signOut() {
    return this.fetch("DELETE", "/authorizations", {});
  }
}

export default AuthModule;
