import { AsyncData } from "#app";
import { FetchError } from "ofetch";
import FetchFactory from "../factory";
import {
  CartRelatedProductsResponse,
  CartResponse,
  CheckoutData,
  CheckoutResponse,
  PaypalOrderResponse,
  CartPaymentMethodsData,
  AddressData,
  shippingRatesByAddress,
  PaypalOrderDataResponse,
  StartExpressPaymentPayload,
  StartExpressPaymentPostResponse,
  updatePayPalOrderShippingMethod,
  expressCheckoutUpdateShippingResponse,
} from "~/types/api/cart.types";
import { ShippingMethodsResponse } from "@/types/api/orders.types";
import { SignInData } from "@/types/api/auth.types";

class CartModule extends FetchFactory {
  /**
   * Get cart
   *
   * @link https://dev-opr.diamondnexus.com/apidoc/#api-Cart-GetApiV1Carts
   * @returns Promise
   * @param userInfo
   * @param uniqueId
   * @param getCartByCartID
   */

  getCart(
    userInfo: SignInData | null,
    uniqueId?: string | null,
    getCartByCartID?: boolean,
  ) {
    const params: { cart_unique_id?: string } = {};
    if ((getCartByCartID && uniqueId) || (uniqueId && !userInfo)) {
      params.cart_unique_id = uniqueId as string;
    }
    return this.fetch<CartResponse>("GET", "/carts", { params });
  }

  /**
   * Create cart item
   *
   * @link https://dev-opr.diamondnexus.com/apidoc/#api-Cart-PostApiV1CartsItems
   * @returns Promise
   * @param data
   */
  addToCart(data: any) {
    return this.fetch<CartResponse>("POST", "/carts/items", {
      body: data,
    });
  }

  /**
   * Update cart item
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/#api-Cart-PatchApiV1CartsItemsId
   * @param id
   * @param data
   * @returns Promise
   */
  updateCartItem(id: number, data: any) {
    return this.fetch<CartResponse>("PATCH", `/carts/items/${id}`, {
      body: data,
    });
  }

  /**
   * Delete cart item
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/#api-Cart-DeleteApiV1CartsItemsId
   * @param id
   * @param data
   * @returns Promise
   */
  deleteCartItem(id: number, data: any) {
    return this.fetch<CartResponse>("DELETE", `/carts/items/${id}`, {
      body: data,
      key: String(id),
    });
  }

  /**
   * Bind gift card or discount to cart
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/#api-Cart-PostApiV1CartsBindGiftCard
   * @returns Promise
   * @param data
   */
  bindDiscount(data: any) {
    return this.fetch<CartResponse>("POST", `/carts/bind-gift-card`, {
      body: data,
    });
  }

  /**
   * Bind gift card or discount to cart
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/#api-Cart-PostApiV1CartsUnbindGiftCard
   * @returns Promise
   * @param data
   */
  unbindDiscount(data: { cart_unique_id: string; gift_card_code: string }) {
    return this.fetch<CartResponse>("POST", `/carts/unbind-gift-card`, {
      body: data,
    });
  }

  /**
   * Storing checkout data
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/#api-Cart-PostApiV1CartsCheckout
   * @param data
   * @returns Promise
   */
  checkout(data: CheckoutData) {
    return this.fetch<CheckoutResponse>("POST", `/carts/checkout`, {
      body: data,
    });
  }

  /**
   * Paypal order
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/#api-Cart-PostApiV1CartsCheckoutPaypalOrder
   * @param data
   * @returns Promise
   */
  paypalOrder(data: { cart_unique_id: string }) {
    return this.fetch<PaypalOrderResponse>(
      "POST",
      `/carts/checkout/paypal/order`,
      {
        body: data,
      },
    );
  }

  /**
   * Get cart related product
   *
   * @link https://dev-opr.diamondnexus.com/apidoc/#api-Cart-GetApiV1CartsCart_unique_idRelatedProducts
   * @returns Promise
   */

  getCartRelatedProducts(uniqueId: string | null) {
    if (!uniqueId)
      return Promise.resolve({ data: [] } as CartRelatedProductsResponse);
    return this.fetch<CartRelatedProductsResponse>(
      "GET",
      `/carts/${uniqueId}/related-products`,
      {},
    );
  }

  /**
   * Start express payment
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/index.html#api-Cart-PostApiV1CartsStartExpressPayment
   * @returns Promise
   */
  //  /carts/checkout/express /carts/start-express-payment
  startExpressPayment(payload: StartExpressPaymentPayload) {
    return this.fetch<StartExpressPaymentPostResponse | CheckoutResponse>(
      "POST",
      "/carts/checkout/express",
      {
        body: payload,
      },
    );
  }

  /**
   * Shipping rates by address
   *
   * @link
   * @returns Promise
   */
  shippingRatesByAddress(payload: shippingRatesByAddress) {
    return this.fetch<ShippingMethodsResponse>(
      "POST",
      "/shipping-rates-by-address",
      {
        body: payload,
      },
    );
    // const config = useRuntimeConfig();
    // return $fetch("/shipping-rates-by-address", {
    //   method: "POST",
    //   body: payload,
    //   baseURL: config.public.apiBase,
    // });
  }

  /**
   * Update payment method
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/#api-Cart-PatchApiV1CartsPaymentMethod
   * @returns Promise
   */
  //  /carts/checkout/express /carts/start-express-payment
  updatePaymentMethod(payload: CartPaymentMethodsData) {
    return this.fetch<any>("PATCH", "/carts/payment-method", {
      body: payload,
    });
  }

  /**
   * Update PayPal Order Shipping Address
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/#api-Cart-PatchApiV1CartsCheckoutPaypalShippingAddress
   * @param data
   * @returns Promise
   */
  updatePayPalOrderShippingAddress(data: {
    cart_unique_id: string;
    paypal_order_id: string;
    shipping_address: AddressData;
  }) {
    return this.fetch<PaypalOrderResponse>(
      "PATCH",
      `/carts/checkout/paypal/shipping-address`,
      {
        body: data,
      },
    );
  }

  /**
   * Update PayPal Order Shipping Method
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/#api-Cart-PatchApiV1CartsCheckoutPaypalShippingMethod
   * @param data
   * @returns Promise
   */
  updatePayPalOrderShippingMethod(data: {
    cart_unique_id: string;
    paypal_order_id: string;
    shipping_method: string | number;
  }) {
    return this.fetch<updatePayPalOrderShippingMethod>( // TODO
      "PATCH",
      `/carts/checkout/paypal/shipping-method`,
      {
        body: data,
      },
    );
  }

  /**
   * Express checkout update shipping
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/#api-Cart-PatchApiV1CartsCheckoutExpressShipping
   * @param data
   * @returns Promise
   */
  expressCheckoutUpdateShipping(data: {
    cart_unique_id: string;
    shipping_address: AddressData;
    shipping_method: string | number;
  }) {
    return this.fetch<expressCheckoutUpdateShippingResponse>(
      "PATCH",
      `/carts/checkout/express/shipping`,
      {
        body: data,
      },
    );
  }

  /**
   * Paypal order data express
   *
   * @link
   * @returns Promise
   * @param cart_unique_id
   * @param paypal_order_id
   */

  getPaypalOrderExpress(cart_unique_id: string, paypal_order_id: string) {
    return this.fetch<PaypalOrderDataResponse>(
      "GET",
      `/carts/checkout/paypal/order`,
      {
        query: {
          cart_unique_id,

          paypal_order_id,
        },
      },
    );
  }

  /**
   * Start checkout event
   *
   * @link
   * @returns Promise
   * @param cart_unique_id
   */

  sendStartCheckoutEvent(cart_unique_id: string) {
    return this.fetch<PaypalOrderDataResponse>(
      "PATCH",

      `/carts/${cart_unique_id}/start-checkout`,
      {
        skipErrors: [404, 500, 400],
      },
    );
  }

  /**
   * Update cart
   *
   * @link
   * @returns Promise
   * @param cart_unique_id
   * @param data
   */
  updateCart(cartUniqueId: string, data: CheckoutData) {
    return this.fetch<CheckoutResponse>("PATCH", `/carts/${cartUniqueId}`, {
      body: data,
      skipErrors: [400],
    });
  }
}

export default CartModule;
