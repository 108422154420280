import { storeToRefs } from "pinia";
import { useGeneralStore } from "~/stores/general";
import { Cart, CartItem, Rate } from "~/types/api/cart.types";
import { OrderData } from "@/types/api/orders.types";

export const useGoogleAnalyticsEvents = () => {
  const generalStore = useGeneralStore();
  const {
    getIntegrationCallbackStatuses: integrationCallbackStatuses,
    settings,
  }: any = storeToRefs(generalStore);

  const isGoogleAnalyticsAvailable = computed(() => {
    return (
      (integrationCallbackStatuses.value.TYPE_GOOGLE_ANALYTICS ||
        integrationCallbackStatuses.value.TYPE_GTM) &&
      Boolean((window as any)?.dataLayer)
    );
  });
  function googleAnalyticsPriceFormat(price: any) {
    price = price || 0;
    return Number(price).toFixed(2);
  }
  function gaPurchaseEvent(data: OrderData) {
    if (!isGoogleAnalyticsAvailable.value || !data) {
      return;
    }
    (window as any).dataLayer.push({ ecommerce: null });
    const discountCodes = data.options.discounts
      ? Object.keys(data.options.discounts)
      : [];
    const giftCardCodes = data.options.gift_cards
      ? Object.keys(data.options.gift_cards)
      : [];
    const codes = [...discountCodes, ...giftCardCodes];
    (window as any).dataLayer.push({
      event: "purchase",
      ecommerce: {
        transaction_id: data.po_number, // ідентифікатор транзакції
        affiliation: "Online Store", // де здійснюється покупка
        value: googleAnalyticsPriceFormat(data.final_amount), // сума транзакції
        tax: googleAnalyticsPriceFormat(data.tax_amount), // налоги, якщо вони є
        shipping: googleAnalyticsPriceFormat(data.shipping_amount), // ціна доставки, якщо вона є
        currency: data.currency_code, // валюта, якщо гривні, то UAH, якщо доллар, то USD
        coupon: codes?.length ? codes.join(", ") : "", // купон, якщо є
        items: data.order_products.map((item) => ({
          item_name: item?.currentTranslation?.title, // назва товару
          item_id: item.id, // ідентифікатор товару
          price: googleAnalyticsPriceFormat(item.base_cost), // ціна товару
          item_brand: generalStore.settings?.company_name, // бренд товару
          item_category: "", // категорія товару
          item_variant: item.configuration_id, // варіант товару
          quantity: item.quantity, // кількість замовленого товару
        })),
      },
    });
  }
  function gaViewItemListEvent(data: any, extraData: any) {
    if (!isGoogleAnalyticsAvailable.value || !data?.length) {
      return;
    }
    (window as any).dataLayer.push({ ecommerce: null });
    (window as any).dataLayer.push({
      event: "view_item_list",
      ecommerce: {
        items: data.map((item: any, index: number) => ({
          item_name: item?.currentTranslation?.title || item.title, // потрібно вказати ім’я або ідентифікатор
          item_id: item.id, // ідентифікатор товару
          price: googleAnalyticsPriceFormat(
            item.default_config?.cost || item.base_cost,
          ), // ціна товару
          item_brand: generalStore.settings?.company_name, // бренд товару
          item_category: item.collections_ids?.[0], // категорія товару
          item_category2: "", // другий рівень категорії товару
          item_category3: "", // третій рівень категорії товару
          item_category4: "", // четвертий рівень категорії товару
          item_variant: item.default_sku, // варіант товару
          item_list_name: extraData?.item_list_name || "", // назва списку товарів
          item_list_id: item.default_sku, // унікальний ідентифікатор переліку товарів
          index: index + 1, // позиція товару у переліку товарів
          quantity: 1, // кількість замовленого товару
        })),
      },
    });
  }
  function gaSelectItemEvent(data: any, extraData: any) {
    if (!isGoogleAnalyticsAvailable.value || !data) {
      return;
    }
    (window as any).dataLayer.push({ ecommerce: null });
    (window as any).dataLayer.push({
      event: "select_item",
      ecommerce: {
        items: [
          {
            item_name: data?.currentTranslation?.title || data.title, // потрібно вказати ім’я або ідентифікатор
            item_id: data.id, // ідентифікатор товару
            price: googleAnalyticsPriceFormat(
              data.default_config?.cost || data.base_cost,
            ), // ціна товару
            item_brand: generalStore.settings?.company_name, // бренд товару
            item_category: data.collections_ids?.[0], // категорія товару
            item_category2: "", // другий рівень категорії товару
            item_category3: "", // третій рівень категорії товару
            item_category4: "", // четвертий рівень категорії товару
            item_variant: data.default_sku, // варіант товару
            item_list_name: extraData?.item_list_name || "", // назва списку товарів
            item_list_id: data.default_sku, // унікальний ідентифікатор переліку товарів
            index: extraData.index + 1, // позиція товару у переліку товарів
            quantity: 1, // кількість замовленого товару
          },
        ],
      },
    });
  }
  function gaViewItemEvent(data: any, configurationData: any) {
    if (!isGoogleAnalyticsAvailable.value || !data) {
      return;
    }
    (window as any).dataLayer.push({ ecommerce: null });
    (window as any).dataLayer.push({
      event: "view_item",
      ecommerce: {
        items: [
          {
            item_name: data?.currentTranslation?.title || data?.title, // назва товару
            item_id: data.id, // ідентифікатор товару
            price: googleAnalyticsPriceFormat(
              configurationData?.cost || data.base_cost,
            ), // ціна товару
            item_brand: generalStore.settings?.company_name, // бренд товару
            item_category:
              data.collections?.[0]?.currentTranslation?.title || "", // категорія товару
            item_category2: "", // другий рівень категорії товару
            item_category3: "", // третій рівень категорії товару
            item_category4: "", // четвертий рівень категорії товару
            item_variant: configurationData?.configuration_id || "", // варіант товару
            item_list_name: "", // назва списку товарів
            item_list_id: configurationData?.configuration_id || "", // унікальний ідентифікатор переліку товарів
            index: 1, // позиція товару у переліку товарів
            quantity: 1, // кількість замовленого товару
          },
        ],
      },
    });
  }
  function gaAddToCartEvent(data: any, configurationData: any, index: number) {
    // const { cartItems } = useCart();

    if (!isGoogleAnalyticsAvailable.value || !data) {
      return;
    }
    (window as any).dataLayer.push({ ecommerce: null });
    (window as any).dataLayer.push({
      event: "add_to_cart",
      ecommerce: {
        items: [
          {
            item_name: data?.currentTranslation?.title || data?.title, // назва товару
            item_id: data.id, // ідентифікатор товару
            price: googleAnalyticsPriceFormat(
              configurationData?.cost || data.base_cost,
            ), // ціна товару
            item_brand: generalStore.settings?.company_name, // бренд товару
            item_category:
              data.collections?.[0]?.currentTranslation.title ||
              data.collections_ids?.[0] ||
              "", // категорія товару
            item_category2: "", // другий рівень категорії товару
            item_category3: "", // третій рівень категорії товару
            item_category4: "", // четвертий рівень категорії товару
            item_variant: configurationData?.configuration_id || "", // варіант товару
            item_list_name: "", // назва списку товарів
            item_list_id: configurationData?.configuration_id || "", // унікальний ідентифікатор переліку товарів
            index, // позиція товару у переліку товарів
            quantity: 1, // кількість замовленого товару
          },
        ],
      },
    });
  }
  function gaRemoveFromCartEvent(data: CartItem, index: number) {
    if (!isGoogleAnalyticsAvailable.value || !data) {
      return;
    }
    (window as any).dataLayer.push({ ecommerce: null });
    (window as any).dataLayer.push({
      event: "remove_from_cart",
      ecommerce: {
        items: [
          {
            item_name: data?.currentTranslation?.title || data?.title, // назва товару
            item_id: data.product_id, // ідентифікатор товару
            price: googleAnalyticsPriceFormat(data.base_cost), // ціна товару
            item_brand: generalStore.settings?.company_name, // бренд товару
            item_category: "", // категорія товару
            item_variant: data.configuration_id, // варіант товару
            item_list_name: "", // назва списку товарів
            item_list_id: data.configuration_id, // унікальний ідентифікатор переліку товарів
            index: index + 1, // позиція товару у переліку товарів
            quantity: data.quantity, // кількість замовленого товару
          },
        ],
      },
    });
  }
  function gaBeginCheckoutEvent(data: CartItem[]) {
    if (!isGoogleAnalyticsAvailable.value || !data?.length) {
      return;
    }
    (window as any).dataLayer.push({ ecommerce: null });
    (window as any).dataLayer.push({
      event: "begin_checkout",
      ecommerce: {
        items: data.map((item, index) => ({
          item_name: item?.currentTranslation?.title || item?.title, // назва товару
          item_id: item.id, // ідентифікатор товару
          price: googleAnalyticsPriceFormat(item.base_cost), // ціна товару
          item_brand: generalStore.settings?.company_name, // бренд товару
          item_category: "", // категорія товару
          item_category2: "", // другий рівень категорії товару
          item_category3: "", // третій рівень категорії товару
          item_category4: "", // четвертий рівень категорії товару
          item_variant: item.configuration_id, // варіант товару
          item_list_name: "", // назва списку товарів
          item_list_id: item.configuration_id, // унікальний ідентифікатор переліку товарі
          index: index + 1, // позиція товару у переліку товарів
          quantity: item.quantity, // кількість замовленого товару
        })),
      },
    });
  }
  function gaAddShippingInfoEvent(data: Cart, shippingMethodData: Rate) {
    if (!isGoogleAnalyticsAvailable.value || !data) {
      return;
    }
    (window as any).dataLayer.push({ ecommerce: null });
    const coupon = data.discount || data.gift_card;
    (window as any).dataLayer.push({
      event: "add_shipping_info",
      ecommerce: {
        shipping_tier: shippingMethodData.deliveryServiceName, // Назва служби доставки
        value: googleAnalyticsPriceFormat(shippingMethodData.price), // Ціна доставки
        currency: settings.value?.currency_code || "", // Валюта доставки
        items: data.items.map((item, index: number) => {
          const discountCodes = item.options.discounts
            ? Object.keys(item.options.discounts)
            : [];
          const giftCardCodes = item.options.gift_cards
            ? Object.keys(item.options.gift_cards)
            : [];
          const codes = [...discountCodes, ...giftCardCodes];
          return {
            item_name: item.title, // Назва товару
            item_id: item.id, // id товару
            price: googleAnalyticsPriceFormat(item.base_cost), // Ціна товару
            item_brand: generalStore.settings?.company_name || "", // Бренд товару
            item_category: "", // Категорія товару
            item_category2: "", // Категорія товару	2
            item_category3: "", // Категорія товару	3
            item_category4: "", // Категорія товару	4
            item_variant: item.configuration_id, // Модифікація
            item_list_name: "", // Група товару
            item_list_id: item.configuration_id, // id групи товару
            index: index + 1, // Порядковий номер товару в категоріі
            quantity: item.quantity, // Кількість товару
            discount: item?.discount_amount || "", // Знижка
            coupon: codes?.length ? codes.join(", ") : "", // Купон
          };
        }),
      },
    });
  }
  function gaDynamicParamsEvent(event: string, data: any) {
    if (!isGoogleAnalyticsAvailable.value || !data) {
      return;
    }
    const { ecomm_prodid, ecomm_pagetype, ecomm_totalvalue, ecomm_category } =
      data;
    (window as any).dataLayer.push({
      event: event || "Dynamic Remarketing",
      dynamicParams: {
        ecomm_prodid: ecomm_prodid || "",
        ecomm_pagetype: ecomm_pagetype || "",
        ecomm_totalvalue: ecomm_totalvalue || "",
        ecomm_category: ecomm_category || "",
      },
    });
  }
  function gaViewCartEvent(data?: Cart | null) {
    if (!isGoogleAnalyticsAvailable.value || !data) {
      return;
    }
    (window as any).dataLayer.push({ ecommerce: null });
    (window as any).dataLayer.push({
      event: "view_cart",
      ecommerce: {
        value: googleAnalyticsPriceFormat(data?.final_amount), // сума транзакції
        currency: settings.value?.currency_code || "", // валюта, якщо гривні, то UAH, якщо доллар, то USD
        items: data?.items?.map((item) => ({
          item_name: item?.currentTranslation?.title, // назва товару
          item_id: item.id, // ідентифікатор товару
          price: googleAnalyticsPriceFormat(item.base_cost), // ціна товару
          item_brand: generalStore.settings?.company_name, // бренд товару
          item_category: "", // категорія товару
          item_variant: item.configuration_id, // варіант товару
          quantity: item.quantity, // кількість замовленого товару
        })),
      },
    });
  }

  function gaViewSearchResultEvent(data: string) {
    if (!isGoogleAnalyticsAvailable.value || !data) {
      return;
    }
    (window as any).dataLayer.push({ ecommerce: null });
    (window as any).dataLayer.push({
      event: "view_search_results",
      ecommerce: {
        search_term: data,
      },
    });
  }

  function gaGenerateLeadEvent(lead_source: string) {
    if (!isGoogleAnalyticsAvailable.value || !lead_source) {
      return;
    }
    (window as any).dataLayer.push({ ecommerce: null });
    (window as any).dataLayer.push({
      event: "generate_lead",
      ecommerce: {
        currency: settings.value?.currency_code || "",
        value: 0,
        lead_source,
      },
    });
  }

  function gaAddToWishlistEvent(product: any) {
    if (!isGoogleAnalyticsAvailable.value || !product) {
      return;
    }
    (window as any).dataLayer.push({ ecommerce: null });
    (window as any).dataLayer.push({
      event: "add_to_wishlist",
      ecommerce: {
        currency: settings.value?.currency_code || "",
        value: product.product_price,
        items: [
          {
            item_name: product?.product_title, // назва товару
            item_id: product?.product_id, // ідентифікатор товару
            price: googleAnalyticsPriceFormat(product.product_price), // ціна товару
            item_brand: generalStore.settings?.company_name, // бренд товару
            item_category: "", // категорія товару
            item_variant: product.configuration_id, // варіант товару
          },
        ],
      },
    });
  }

  function gaRemoveFromWishlistEvent(product: any) {
    if (!isGoogleAnalyticsAvailable.value || !product) {
      return;
    }
    (window as any).dataLayer.push({ ecommerce: null });
    (window as any).dataLayer.push({
      event: "favorites_remove",
      ecommerce: {
        currency: settings.value?.currency_code || "",
        value: product.product_price,
        items: [
          {
            item_name: product?.product_title, // назва товару
            item_id: product?.product_id, // ідентифікатор товару
            price: googleAnalyticsPriceFormat(product.product_price), // ціна товару
            item_brand: generalStore.settings?.company_name, // бренд товару
            item_category: "", // категорія товару
            item_variant: product.configuration_id, // варіант товару
          },
        ],
      },
    });
  }

  function gaViewWishlistEvent(products: any) {
    if (!isGoogleAnalyticsAvailable.value || !products) {
      return;
    }
    (window as any).dataLayer.push({ ecommerce: null });
    (window as any).dataLayer.push({
      event: "favorites_view",
      ecommerce: {
        currency: settings.value?.currency_code || "",
        value: products.reduce(
          (acc, product: any) => acc + Number(product.cost),
          0,
        ),
        items: products.map((product: any) => ({
          item_name: product?.currentTranslation?.title, // назва товару
          item_id: product?.currentTranslation?.id, // ідентифікатор товару
          price: googleAnalyticsPriceFormat(product.cost), // ціна товару
          item_brand: generalStore.settings?.company_name, // бренд товару
          item_category: "", // категорія товару
          item_variant: product.configuration_id, // варіант товару
        })),
      },
    });
  }
  return {
    integrationCallbackStatuses,
    googleAnalyticsPriceFormat,
    gaPurchaseEvent,
    gaViewItemListEvent,
    gaSelectItemEvent,
    gaViewItemEvent,
    gaAddToCartEvent,
    gaRemoveFromCartEvent,
    gaBeginCheckoutEvent,
    gaAddShippingInfoEvent,
    gaDynamicParamsEvent,
    gaViewCartEvent,
    gaViewSearchResultEvent,
    gaGenerateLeadEvent,
    gaAddToWishlistEvent,
    gaRemoveFromWishlistEvent,
    gaViewWishlistEvent,
  };
};
