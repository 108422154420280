import { default as _91_46_46_46slug_93ILszah2VNpMeta } from "/app/pages/[...slug].vue?macro=true";
import { default as _91slug_939BhwXGwnBKMeta } from "/app/pages/blog/[section]/[slug].vue?macro=true";
import { default as indexIsQ8vQihJKMeta } from "/app/pages/blog/[section]/index.vue?macro=true";
import { default as indexgWaMlevJ7RMeta } from "/app/pages/blog/index.vue?macro=true";
import { default as _91id_93y9w7wnh1syMeta } from "/app/pages/cart/[id].vue?macro=true";
import { default as indexAacQV8oPDMMeta } from "/app/pages/cart/index.vue?macro=true";
import { default as _91id_93qGb7sUHr7XMeta } from "/app/pages/checkout/[id].vue?macro=true";
import { default as index7ZtduOIoVXMeta } from "/app/pages/checkout/index.vue?macro=true";
import { default as _91id_93AE9MRUqPLBMeta } from "/app/pages/checkout/success/[id].vue?macro=true";
import { default as favoritesYMtsvzhBvNMeta } from "/app/pages/favorites.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as ordersFd8PY4blJCMeta } from "/app/pages/orders.vue?macro=true";
import { default as _91slug_93FPU7I0zot8Meta } from "/app/pages/product/[slug].vue?macro=true";
import { default as profileupBlgdy3vLMeta } from "/app/pages/profile.vue?macro=true";
import { default as _91token_93K7acn9QelAMeta } from "/app/pages/recover-password/[token].vue?macro=true";
import { default as track_45orderv0hHwuF2jbMeta } from "/app/pages/track-order.vue?macro=true";
import { default as _91token_935yfqHd94b8Meta } from "/app/pages/verify/[token].vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93ILszah2VNpMeta?.name ?? "slug___en",
    path: _91_46_46_46slug_93ILszah2VNpMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93ILszah2VNpMeta || {},
    alias: _91_46_46_46slug_93ILszah2VNpMeta?.alias || [],
    redirect: _91_46_46_46slug_93ILszah2VNpMeta?.redirect,
    component: () => import("/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939BhwXGwnBKMeta?.name ?? "blog-section-slug___en",
    path: _91slug_939BhwXGwnBKMeta?.path ?? "/blog/:section()/:slug()",
    meta: _91slug_939BhwXGwnBKMeta || {},
    alias: _91slug_939BhwXGwnBKMeta?.alias || [],
    redirect: _91slug_939BhwXGwnBKMeta?.redirect,
    component: () => import("/app/pages/blog/[section]/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexIsQ8vQihJKMeta?.name ?? "blog-section___en",
    path: indexIsQ8vQihJKMeta?.path ?? "/blog/:section()",
    meta: indexIsQ8vQihJKMeta || {},
    alias: indexIsQ8vQihJKMeta?.alias || [],
    redirect: indexIsQ8vQihJKMeta?.redirect,
    component: () => import("/app/pages/blog/[section]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgWaMlevJ7RMeta?.name ?? "blog___en",
    path: indexgWaMlevJ7RMeta?.path ?? "/blog",
    meta: indexgWaMlevJ7RMeta || {},
    alias: indexgWaMlevJ7RMeta?.alias || [],
    redirect: indexgWaMlevJ7RMeta?.redirect,
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93y9w7wnh1syMeta?.name ?? "cart-id___en",
    path: _91id_93y9w7wnh1syMeta?.path ?? "/cart/:id()",
    meta: _91id_93y9w7wnh1syMeta || {},
    alias: _91id_93y9w7wnh1syMeta?.alias || [],
    redirect: _91id_93y9w7wnh1syMeta?.redirect,
    component: () => import("/app/pages/cart/[id].vue").then(m => m.default || m)
  },
  {
    name: indexAacQV8oPDMMeta?.name ?? "cart___en",
    path: indexAacQV8oPDMMeta?.path ?? "/cart",
    meta: indexAacQV8oPDMMeta || {},
    alias: indexAacQV8oPDMMeta?.alias || [],
    redirect: indexAacQV8oPDMMeta?.redirect,
    component: () => import("/app/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93qGb7sUHr7XMeta?.name ?? "checkout-id___en",
    path: _91id_93qGb7sUHr7XMeta?.path ?? "/checkout/:id()",
    meta: _91id_93qGb7sUHr7XMeta || {},
    alias: _91id_93qGb7sUHr7XMeta?.alias || [],
    redirect: _91id_93qGb7sUHr7XMeta?.redirect,
    component: () => import("/app/pages/checkout/[id].vue").then(m => m.default || m)
  },
  {
    name: index7ZtduOIoVXMeta?.name ?? "checkout___en",
    path: index7ZtduOIoVXMeta?.path ?? "/checkout",
    meta: index7ZtduOIoVXMeta || {},
    alias: index7ZtduOIoVXMeta?.alias || [],
    redirect: index7ZtduOIoVXMeta?.redirect,
    component: () => import("/app/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93AE9MRUqPLBMeta?.name ?? "checkout-success-id___en",
    path: _91id_93AE9MRUqPLBMeta?.path ?? "/checkout/success/:id()",
    meta: _91id_93AE9MRUqPLBMeta || {},
    alias: _91id_93AE9MRUqPLBMeta?.alias || [],
    redirect: _91id_93AE9MRUqPLBMeta?.redirect,
    component: () => import("/app/pages/checkout/success/[id].vue").then(m => m.default || m)
  },
  {
    name: favoritesYMtsvzhBvNMeta?.name ?? "favorites___en",
    path: favoritesYMtsvzhBvNMeta?.path ?? "/favorites",
    meta: favoritesYMtsvzhBvNMeta || {},
    alias: favoritesYMtsvzhBvNMeta?.alias || [],
    redirect: favoritesYMtsvzhBvNMeta?.redirect,
    component: () => import("/app/pages/favorites.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___en",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: ordersFd8PY4blJCMeta?.name ?? "orders___en",
    path: ordersFd8PY4blJCMeta?.path ?? "/orders",
    meta: ordersFd8PY4blJCMeta || {},
    alias: ordersFd8PY4blJCMeta?.alias || [],
    redirect: ordersFd8PY4blJCMeta?.redirect,
    component: () => import("/app/pages/orders.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FPU7I0zot8Meta?.name ?? "product-slug___en",
    path: _91slug_93FPU7I0zot8Meta?.path ?? "/product/:slug()",
    meta: _91slug_93FPU7I0zot8Meta || {},
    alias: _91slug_93FPU7I0zot8Meta?.alias || [],
    redirect: _91slug_93FPU7I0zot8Meta?.redirect,
    component: () => import("/app/pages/product/[slug].vue").then(m => m.default || m)
  },
  {
    name: profileupBlgdy3vLMeta?.name ?? "profile___en",
    path: profileupBlgdy3vLMeta?.path ?? "/profile",
    meta: profileupBlgdy3vLMeta || {},
    alias: profileupBlgdy3vLMeta?.alias || [],
    redirect: profileupBlgdy3vLMeta?.redirect,
    component: () => import("/app/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: _91token_93K7acn9QelAMeta?.name ?? "recover-password-token___en",
    path: _91token_93K7acn9QelAMeta?.path ?? "/recover-password/:token()",
    meta: _91token_93K7acn9QelAMeta || {},
    alias: _91token_93K7acn9QelAMeta?.alias || [],
    redirect: _91token_93K7acn9QelAMeta?.redirect,
    component: () => import("/app/pages/recover-password/[token].vue").then(m => m.default || m)
  },
  {
    name: track_45orderv0hHwuF2jbMeta?.name ?? "track-order___en",
    path: track_45orderv0hHwuF2jbMeta?.path ?? "/track-order",
    meta: track_45orderv0hHwuF2jbMeta || {},
    alias: track_45orderv0hHwuF2jbMeta?.alias || [],
    redirect: track_45orderv0hHwuF2jbMeta?.redirect,
    component: () => import("/app/pages/track-order.vue").then(m => m.default || m)
  },
  {
    name: _91token_935yfqHd94b8Meta?.name ?? "verify-token___en",
    path: _91token_935yfqHd94b8Meta?.path ?? "/verify/:token()",
    meta: _91token_935yfqHd94b8Meta || {},
    alias: _91token_935yfqHd94b8Meta?.alias || [],
    redirect: _91token_935yfqHd94b8Meta?.redirect,
    component: () => import("/app/pages/verify/[token].vue").then(m => m.default || m)
  }
]