import FetchFactory from "../factory";
import {
  PostResponse,
  PostsResponse,
  BlogCategoryResponse,
  BlogCategoriesResponse,
  BlogQueryParams,
} from "~/types/api/blog.types";

class BlogModule extends FetchFactory {
  /**
   * Get blog categories
   *
   * @link https://devapi.diamondnexus.com/public/apidoc/#api-Blog-GetApiV1BlogCategories
   * @returns Promise
   */
  getBlogCategories() {
    return this.fetch<BlogCategoriesResponse>("GET", "/blog/categories");
  }

  /**
   * Get blog category by slug
   *
   * @link https://devapi.diamondnexus.com/public/apidoc/#api-Blog-GetApiV1BlogCategoriesSlug
   * @param slug
   * @returns Promise
   */
  getBlogCategoryBySlug(slug: string) {
    return this.fetch<BlogCategoryResponse>("GET", `/blog/categories/${slug}`);
  }

  /**
   * Get blog category posts
   *
   * @link https://devapi.diamondnexus.com/public/apidoc/#api-Blog-GetApiV1BlogCategoriesSlugPosts
   * @param slug
   * @param params
   * @returns Promise
   */
  getPostsByCategory(slug: string, params: BlogQueryParams) {
    return this.fetch<PostsResponse>("GET", `/blog/categories/${slug}/posts`, {
      params,
    });
  }

  /**
   * Get posts
   *
   * @link https://devapi.diamondnexus.com/public/apidoc/#api-Blog-GetApiV1BlogPostsType
   * @param params
   * @returns Promise
   */
  getPosts(params: BlogQueryParams) {
    return this.fetch<PostsResponse>("GET", "/blog/posts", { params });
  }

  /**
   * Get post by type and slug
   *
   * @link https://devapi.diamondnexus.com/public/apidoc/#api-Blog-GetApiV1BlogPostsTypeSlug
   * @param type
   * @param slug
   * @returns Promise
   */
  getPostByType(type: string, slug: string) {
    return this.fetch<PostResponse>("GET", `/blog/posts/${type}/${slug}`);
  }

  /**
   * Get post by slug
   *
   * @link https://devapi.diamondnexus.com/public/apidoc/#api-Blog-GetApiV1BlogPostsSlug
   * @param slug
   * @returns Promise
   */
  getPostBySlug(slug: string) {
    return this.fetch<PostResponse>("GET", `/blog/posts/${slug}`);
  }
}

export default BlogModule;
