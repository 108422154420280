import { useGeneralStore } from "@/stores/general";
export default defineNuxtPlugin(async (nuxtApp) => {
  const generalStore = useGeneralStore();
  const INTERVAL_PERIOD = 1000 * 60 * 3; // 3 minutes

  async function getData() {
    const API_DOMAIN = nuxtApp.$config.public.apiBase.replace("/api/v1", "");
    const res = await useFetch<Record<string, number>>(API_DOMAIN + "/cv");
    return res.data.value || {};
  }

  const data = await getData();
  if (data) {
    generalStore.cacheVersions = data;
  }
  if (process.client) {
    setInterval(async () => {
      generalStore.cacheVersions = await getData();
    }, INTERVAL_PERIOD);
  }
});
