import { useGeneralStore } from "~/stores/general";
export const useMetaIcons = () => {
  const generalStore = useGeneralStore();

  const filePath = `${generalStore.settings?.cdn_domain}/favicon/`;

  type FileItem = {
    rel?: string;
    type?: string;
    sizes?: string;
    href?: string;
    name?: string;
    content?: string;
  };

  const filesListLinks: FileItem[] = [
    // {
    //   rel: "icon",
    //   type: "image/png",
    //   sizes: "36x36",
    //   href: "android-icon-36x36.png",
    // },
    // {
    //   rel: "icon",
    //   type: "image/png",
    //   sizes: "48x48",
    //   href: "android-icon-48x48.png",
    // },
    // {
    //   rel: "icon",
    //   type: "image/png",
    //   sizes: "72x72",
    //   href: "android-icon-72x72.png",
    // },
    // {
    //   rel: "icon",
    //   type: "image/png",
    //   sizes: "96x96",
    //   href: "android-icon-96x96.png",
    // },
    // {
    //   rel: "icon",
    //   type: "image/png",
    //   sizes: "144x144",
    //   href: "android-icon-144x144.png",
    // },
    // {
    //   rel: "icon",
    //   type: "image/png",
    //   sizes: "192x192",
    //   href: "android-icon-192x192.png",
    // },
    {
      rel: "apple-touch-icon",
      sizes: "57x57",
      href: filePath + "apple-icon-57x57.png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "60x60",
      href: filePath + "apple-icon-60x60.png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "72x72",
      href: filePath + "apple-icon-72x72.png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "76x76",
      href: filePath + "apple-icon-76x76.png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "114x114",
      href: filePath + "apple-icon-114x114.png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "120x120",
      href: filePath + "apple-icon-120x120.png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "144x144",
      href: filePath + "apple-icon-144x144.png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "152x152",
      href: filePath + "apple-icon-152x152.png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "180x180",
      href: filePath + "apple-icon-180x180.png",
    },
    {
      rel: "apple-touch-icon-precomposed",
      href: filePath + "apple-icon-precomposed.png",
    },
    {
      rel: "apple-touch-icon",
      href: filePath + "apple-icon.png",
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "16x16",
      href: filePath + "favicon-16x16.png",
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "32x32",
      href: filePath + "favicon-32x32.png",
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "96x96",
      href: filePath + "favicon-96x96.png",
    },
    {
      rel: "icon",
      type: "image/x-icon",
      href: filePath + "favicon.ico",
    },
    {
      rel: "manifest",
      href: filePath + "manifest.json",
    },
    // {
    //   rel: "icon",
    //   type: "image/png",
    //   sizes: "70x70",
    //   href: "ms-icon-70x70.png",
    // },
    // {
    //   rel: "icon",
    //   type: "image/png",
    //   sizes: "144x144",
    //   href: "ms-icon-144x144.png",
    // },
    // {
    //   rel: "icon",
    //   type: "image/png",
    //   sizes: "150x150",
    //   href: "ms-icon-150x150.png",
    // },
    // {
    //   rel: "icon",
    //   type: "image/png",
    //   sizes: "310x310",
    //   href: "ms-icon-310x310.png",
    // },
  ];

  // const filesListLinks = generateMetaLinks(FILES_LIST);

  const filesListMeta = [
    {
      name: "msapplication-config",
      content: filePath + "browserconfig.xml",
    },
  ];

  // function generateMetaLinks(list: FileItem[]) {
  //   return list.map((el) => {
  //     if (el.content) {
  //       return {
  //         ...el,
  //         content: filePath + el.content,
  //       };
  //     }
  //     return {
  //       ...el,
  //       href: filePath + el.href,
  //     };
  //   });
  // }

  return {
    filesListLinks,
    filesListMeta,
  };
};
