import FetchFactory from "../factory";
import { BootstrapResponse } from "~/types/api/bootstrap.types";
import { FiltersResponse } from "~/types/api/filters.types";
import {
  PaymentGatewayResponse,
  PaymentMethodResponse,
  QuestionParams,
  LanguagesResponse,
  ImpactParams,
  ImpactResponse,
  IPopupsParams,
  IPopupsResponse,
  IDiscountCampaignResponse,
} from "~/types/api/general.types";

class GeneralModule extends FetchFactory {
  /**
   * Get Languages
   *
   * @link https://dev-opr.diamondnexus.com/apidoc/#api-Admin_Menu-GetAdminApiV1MenuMass
   * @returns Promise
   */
  getLanguages() {
    return this.fetch<LanguagesResponse>("GET", "/languages", {});
  }

  /**
   * Get bootstrap
   *
   * @link https://dev-opr.diamondnexus.com/apidoc/#api-Public-GetApiV1Bootstrap
   * @returns Promise
   */
  getBootstrap() {
    return this.fetch<BootstrapResponse>("GET", "/bootstrap", {});
  }

  /**
   * Get all filters
   *
   * @link https://dev-opr.diamondnexus.com/apidoc/#api-Events-GetApiV1Filters
   * @returns Promise
   */
  getFilters() {
    return this.fetch<FiltersResponse>("GET", "/filters", {});
  }

  /**
   * Subscribe
   *
   * @link https://dev-opr.diamondnexus.com/apidoc/#api-Public-PostApiV1Subscribe
   * @param data
   * @returns Promise
   */
  subscribe(data: { email: string }) {
    return this.fetch("POST", "/subscribe", {
      body: data,
    });
  }

  /**
   * Get Payment Gateways List
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/#api-Payment_Gateway-GetApiV1PaymentGateways
   * @param data
   * @returns Promise
   */
  getPaymentGateways() {
    return this.fetch<PaymentGatewayResponse>("GET", "/payment-gateways", {});
  }

  /**
   * Get Payment Methods List
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/index.html#api-Payment_Method-GetApiV1PaymentMethods
   * @param params
   * @returns Promise
   */
  getPaymentMethodsList() {
    return this.fetch<PaymentMethodResponse>("GET", "/payment-methods", {});
  }

  /**
   * Question
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/index.html#api-Payment_Method-GetApiV1PaymentMethods
   * @returns Promise
   * @param data
   */
  contactUs(data: QuestionParams) {
    return this.fetch<any>("POST", "/questions", {
      body: data,
      skipErrors: [404, 500, 400],
    });
  }

  /**
   * Send Irclickid Impact
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/#api-Impact
   * @returns Promise
   * @param data
   */
  sendIrclickidImpact(data: ImpactParams) {
    return this.fetch<ImpactResponse>("POST", "/impact", {
      body: data,
      skipErrors: [404, 500, 400],
    });
  }

  /**
   * Get discount campaigns
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/#api-Admin_Campaign_Discount
   */
  getDiscountCampaigns() {
    return this.fetch<IDiscountCampaignResponse>(
      "GET",
      "/campaign-discount",
      {},
    );
  }

  /**
   * Get popups
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/#api-Popups-GetApiV1Popups
   * @param params
   */
  getPopups(params: IPopupsParams) {
    return this.fetch<IPopupsResponse>("GET", "/popups", { params });
  }

  /**
   * Question
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/index.html#api-Payment_Method-GetApiV1PaymentMethods
   * @returns Promise
   * @param data
   */
  zenDeskContactUs(data: QuestionParams) {
    return this.fetch<any>("POST", "/contact-us", {
      body: data,
      skipErrors: [404, 500, 400],
    });
  }
}

export default GeneralModule;
