import FetchFactory from "../factory";
import { ReviewsResponse } from "~/types/api/reviews.types";

class ReviewsModule extends FetchFactory {
  /**
   * Get reviews
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/#api-Page-GetApiV1PagesPageid
   * @returns Promise
   */
  getReviews(params: any) {
    return this.fetch<ReviewsResponse>("GET", `/reviews`, {
      query: params,
    });
  }

  /**
   * Get reviews
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/#api-Page-GetApiV1PagesPageid
   * @returns Promise
   */
  leaveReviews(params: FormData) {
    return this.fetch<ReviewsResponse>("POST", `/reviews`, {
      body: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  }
}

export default ReviewsModule;
