import { useFetchInstance } from "~/api";
import { BootstrapResponse } from "~/types/api/bootstrap.types";
import { FiltersResponse } from "~/types/api/filters.types";
import {
  PaymentGatewayResponse,
  PaymentMethodResponse,
  QuestionParams,
  LanguagesResponse,
  ImpactParams,
  ImpactResponse,
  IPopupsParams,
  IPopupsResponse,
  IDiscountCampaignResponse,
} from "@/types/api/general.types";

export const generalApi = {
  /**
   * Get Languages
   *
   * @link https://dev-opr.diamondnexus.com/apidoc/#api-Admin_Menu-GetAdminApiV1MenuMass
   * @returns {*}
   */
  getLanguages() {
    return useFetchInstance<LanguagesResponse>("/languages");
  },
  /**
   * Get bootstrap
   *
   * @link https://dev-opr.diamondnexus.com/apidoc/#api-Public-GetApiV1Bootstrap
   * @returns {*}
   */
  getBootstrap() {
    return useFetchInstance<BootstrapResponse>("/bootstrap");
  },

  /**
   * Get all filters
   *
   * @link https://dev-opr.diamondnexus.com/apidoc/#api-Events-GetApiV1Filters
   * @returns {*}
   */
  getFilters() {
    return useFetchInstance<FiltersResponse>("/filters");
  },

  /**
   * Subscribe
   *
   * @link https://dev-opr.diamondnexus.com/apidoc/#api-Public-PostApiV1Subscribe
   * @param data
   * @returns {*}
   */
  subscribe(data: { email: string }) {
    return useFetchInstance("/subscribe", {
      method: "POST",
      body: data,
    });
  },
  /**
   * Get Payment Gateways List
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/#api-Payment_Gateway-GetApiV1PaymentGateways
   * @param data
   * @returns {*}
   */
  getPaymentGateways() {
    return useFetchInstance<PaymentGatewayResponse>("/payment-gateways");
  },
  /**
   * Get Payment Methods List
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/index.html#api-Payment_Method-GetApiV1PaymentMethods
   * @param params
   * @returns {*}
   */
  getPaymentMethodsList() {
    return useFetchInstance<PaymentMethodResponse>("/payment-methods", {
      method: "GET",
    });
  },

  /**
   * Question
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/index.html#api-Payment_Method-GetApiV1PaymentMethods
   * @returns {*}
   * @param data
   */
  contactUs(data: QuestionParams) {
    return useFetchInstance<any>("/questions", {
      method: "POST",
      body: data,
    });
  },

  /**
   * Send Irclickid Impact
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/#api-Impact
   * @returns {*}
   * @param data
   */
  sendIrclickidImpact(data: ImpactParams) {
    return useFetchInstance<ImpactResponse>(
      "/impact",
      {
        method: "POST",
        body: data,
      },
      { skipErrors: [404, 500, 400] },
    );
  },

  /**
   * Get discount campaigns
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/#api-Admin_Campaign_Discount
   */
  getDiscountCampaigns() {
    return useFetchInstance<IDiscountCampaignResponse>("/campaign-discount");
  },

  /**
   * Get popups
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/#api-Popups-GetApiV1Popups
   * @param params
   */
  getPopups(params: IPopupsParams) {
    return useFetchInstance<IPopupsResponse>("/popups", { params });
  },
};
