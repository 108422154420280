import { useStorage } from "@vueuse/core";
import { isToday } from "~/utils";
import {
  IDiscountCampaignPopup,
  IPopup,
  PopupFrequencyEnum,
} from "~/types/api/general.types";

export const POPUPS_LOCALSTORAGE_KEY = "popups";

export interface IStoragePopup extends Pick<IPopup, "id" | "frequency"> {
  last_opened_at: Date | null;
}

function checkFrequency(
  popup: IPopup | IDiscountCampaignPopup,
  foundedPopup: IStoragePopup,
  pageLoaded: boolean,
) {
  switch (popup.frequency) {
    case PopupFrequencyEnum.Once: {
      if (!foundedPopup.last_opened_at) {
        return popup;
      }
      return null;
    }
    case PopupFrequencyEnum.EachPageRefresh: {
      if (!pageLoaded) {
        return popup;
      }
      return null;
    }
    case PopupFrequencyEnum.EachPageView: {
      return popup;
    }
    case PopupFrequencyEnum.PerDay: {
      if (
        !foundedPopup.last_opened_at ||
        !isToday(new Date(foundedPopup.last_opened_at))
      ) {
        return popup;
      }
      return null;
    }
    default: {
      return null;
    }
  }
}

function findPriorityPopup(popups: IPopup[]): IPopup | null {
  return (
    popups.reduce((acc, popup) => {
      if (!acc.length) {
        acc.push(popup);
      } else if (popup.priority > acc[0].priority) {
        acc = [popup];
      }
      return acc;
    }, [] as IPopup[])[0] || null
  );
}

export default defineNuxtPlugin(() => {
  const router = useRouter();

  const generalStore = useGeneralStore();

  const { $api } = useNuxtApp();

  async function getPopups(route: string) {
    try {
      const { data } = await $api.general.getPopups({ url: route });

      return findPriorityPopup(data || []);
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  function getDiscountCampaignPopup(route: string) {
    let discountCampaignPopups: IDiscountCampaignPopup[] =
      generalStore.discountCampaignsData.popups || [];
    let discountCampaignPopup: IDiscountCampaignPopup | null = null;
    if (discountCampaignPopups.length) {
      const discountCampaignTopBannerId =
        generalStore.discountCampaignsData?.topBanner?.discount_campaign_id;
      discountCampaignPopups = discountCampaignPopups.filter(
        (item) =>
          !item.pages?.length ||
          item.pages.some((page) => page.page_link === route),
      );
      discountCampaignPopup =
        discountCampaignPopups.find(
          (item) => item.discount_campaign_id === discountCampaignTopBannerId,
        ) ||
        getRandomArrayItem(discountCampaignPopups) ||
        null;
    }

    return discountCampaignPopup;
  }

  let pageLoaded = false;
  let timeOut: NodeJS.Timeout;
  // app.router.afterEach - will wait for the script to finish executing in other plugins with await, don't need any hooks for Discount Campaigns loading
  router.afterEach((to) => {
    clearTimeout(timeOut);
    timeOut = setTimeout(async () => {
      const currentPopup =
        (await getPopups(to.path)) || getDiscountCampaignPopup(to.path);

      if (!currentPopup) return;
      const storePopups = useStorage<IStoragePopup[]>(
        POPUPS_LOCALSTORAGE_KEY,
        [],
      );

      const foundedPopup = storePopups.value.find(
        (popup) => popup.id === currentPopup.id,
      );

      const notEqualFrequency =
        foundedPopup && foundedPopup.frequency !== currentPopup.frequency;

      if (!foundedPopup) {
        const popups = [
          ...storePopups.value,
          {
            id: currentPopup.id,
            frequency: currentPopup.frequency,
            last_opened_at: null,
          },
        ];
        storePopups.value = popups;
        generalStore.setSitePopup(currentPopup);
      } else if (notEqualFrequency) {
        const popups = storePopups.value.map((item) => {
          return item.id === currentPopup.id
            ? {
                id: currentPopup.id,
                frequency: currentPopup.frequency,
                last_opened_at: null,
              }
            : item;
        });
        storePopups.value = popups;
        generalStore.setSitePopup(currentPopup);
      } else {
        const popup = checkFrequency(currentPopup, foundedPopup, pageLoaded);
        generalStore.setSitePopup(popup);
      }
      pageLoaded = true;
    }, 4000);
  });
});
