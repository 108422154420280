import { useGeneralStore } from "~/stores/general";
import { IntegrationsType } from "~/types/api/bootstrap.types";

export default defineNuxtPlugin((nuxtApp) => {
  const generalStore = useGeneralStore();
  const gtmIntegration =
    generalStore.bootstrap?.integrations?.[IntegrationsType.TYPE_GTM];

  if (!gtmIntegration?.gtm_id) {
    return;
  }

  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
    const f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != "dataLayer" ? "&l=" + l : "";

    j.async = true;
    j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
    j.onload = () => {
      generalStore.setIntegrationCallbackStatus({
        integrationName: "TYPE_GOOGLE_ANALYTICS",
        status: true,
      });
    };
    j.onerror = () => {
      console.log("GTM script error");
    };
    f.parentNode.insertBefore(j, f);
  })(window, document, "script", "dataLayer", gtmIntegration.gtm_id);
});
