import { useGeneralStore } from "~/stores/general";
import {
  IntegrationsType,
  IntegrationCallbackData,
} from "~/types/api/bootstrap.types";

function addScripts(
  str: string,
  to: "body" | "head",
  pos?: "start" | "end",
  callbackData?: IntegrationCallbackData,
) {
  const generalStore = useGeneralStore();
  // Create an element outside the document to parse the string with
  const div = document.createElement("div");

  // Parse the string
  div.innerHTML = str;

  // Copy those nodes to the real `head`, duplicating script elements so
  // they get processed
  let node = div.firstChild as HTMLScriptElement | null;
  while (node) {
    const next = node.nextSibling;
    if (node.tagName === "SCRIPT") {
      // Just appending this element wouldn't run it, we have to make a fresh copy
      const newNode = document.createElement("script");
      [...node.attributes].forEach((attr) => {
        newNode.setAttribute(attr.nodeName, attr.nodeValue || "");
      });
      while (node.firstChild) {
        // Note we have to clone these nodes
        newNode.appendChild(node.firstChild.cloneNode(true));
        node.removeChild(node.firstChild);
      }
      node = newNode;
    }
    const cloneNode = node.cloneNode(true) as HTMLScriptElement;

    if (
      callbackData &&
      callbackData.integrationScriptHasSrc &&
      cloneNode.tagName === "SCRIPT" &&
      cloneNode.src
    ) {
      cloneNode.onload = () => {
        generalStore.setIntegrationCallbackStatus({
          integrationName: callbackData.integrationName,
          status: true,
        });
      };
    } else if (
      callbackData &&
      !callbackData.integrationScriptHasSrc &&
      cloneNode.tagName === "SCRIPT"
    ) {
      generalStore.setIntegrationStatusByObjectName({
        integrationName: callbackData.integrationName,
        integrationObjectName: callbackData.integrationObjectName,
      });
    }
    if (pos === "start") {
      document[to].prepend(cloneNode);
    } else {
      document[to].append(cloneNode);
    }

    node = next as HTMLScriptElement;
  }
}

export default defineNuxtPlugin(() => {
  const generalStore = useGeneralStore();

  const FacebookPixel =
    generalStore.bootstrap?.integrations?.[
      IntegrationsType.TYPE_FACEBOOK_PIXEL
    ];

  if (FacebookPixel) {
    FacebookPixel.callbackData = {
      integrationName: "TYPE_FACEBOOK_PIXEL",
      integrationObjectName: "fbq",
      integrationScriptHasSrc: false,
    };
  }

  const GoogleAnalytics =
    generalStore.bootstrap?.integrations?.[
      IntegrationsType.TYPE_GOOGLE_ANALYTICS
    ];

  if (GoogleAnalytics) {
    GoogleAnalytics.callbackData = {
      integrationName: "TYPE_GOOGLE_ANALYTICS",
      integrationObjectName: "dataLayer",
      integrationScriptHasSrc: false,
    };
  }

  const Hotjar =
    generalStore.bootstrap?.integrations?.[IntegrationsType.TYPE_HOTJAR];

  const ZendeskChat =
    generalStore.bootstrap?.integrations?.[IntegrationsType.TYPE_ZENDESK_CHAT];

  const CustomScripts =
    generalStore.bootstrap?.integrations?.[
      IntegrationsType.TYPE_CUSTOM_SCRIPTS
    ];

  const scripts = [FacebookPixel, GoogleAnalytics, Hotjar, ZendeskChat];

  CustomScripts?.scripts.forEach((script) => scripts.push(script));

  setTimeout(() => {
    scripts.forEach((script) => {
      if (script) {
        let insertTo: "body" | "head" = "head";
        let position: "start" | "end" = "end";
        if (script.position === "start_body") {
          insertTo = "body";
          position = "start";
        }
        if (script.position === "end_body") {
          insertTo = "body";
          position = "end";
        }
        addScripts(script.script, insertTo, position, script.callbackData);
      }
    });
  }, 0);
});
