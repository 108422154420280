import { SignInData } from "~/types/api/auth.types";

export function useCookiesService() {
  const cartUniqueId = useCookie("cart_unique_id");
  const clientIp = useCookie("client_ip");
  const orderAddress = useCookie("order_address");
  const uidCookie = useCookie<string>("uid");
  const irclickid = useCookie("irclickid");
  const wishlistId = useCookie("wishlist_id", { maxAge: 60 * 60 * 24 * 3 });
  const userInfoData = useCookie<SignInData | null>("user_info");

  return {
    cartUniqueId,
    clientIp,
    orderAddress,
    uidCookie,
    irclickid,
    wishlistId,
    userInfoData,
  };
}
