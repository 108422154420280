import { useGeneralStore } from "~/stores/general";
import { IntegrationsType } from "~/types/api/bootstrap.types";
import { EventsParams } from "~/types/api/events.types";

export const useZendeskAndKlaviyoEvents = (payload: EventsParams) => {
  const generalStore = useGeneralStore();
  const isEventEnabledZendesk =
    generalStore.bootstrap?.integrations?.[IntegrationsType.TYPE_ZENDESK_CHAT]
      ?.events_enabled;
  const isEventEnabledKlaviyo =
    generalStore.bootstrap?.integrations?.[IntegrationsType.TYPE_KLAVIYO]
      ?.events_enabled;

  if (!isEventEnabledZendesk && !isEventEnabledKlaviyo) return;
  useSendEventToBack(payload);
};

export const useSendEventToBack = (payload: EventsParams) => {
  const { $api } = useNuxtApp();

  $api.events.sendEventToBack(payload).catch((error) => {
    console.error("'Error sending ZendeskAndKlaviyoEvents:'", error);
  });
};
