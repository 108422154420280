import type { FetchOptions } from "ofetch";
import { FetchContext } from "ofetch";
import { Composer } from "vue-i18n";
import { useGeneralStore } from "~/stores/general";
import { addCurrentTranslation } from "@/utils";
import GeneralModule from "~/api/modules/general";
import PagesModule from "~/api/modules/pages";
import BlocksModule from "~/api/modules/blocks";
import WishlistModule from "~/api/modules/wishlist";
import CartModule from "~/api/modules/cart";
import AuthModule from "~/api/modules/auth";
import CatalogModule from "~/api/modules/catalog";
import OrdersModule from "~/api/modules/orders";
import CollectionsModule from "~/api/modules/collections";
import EntitiesModule from "~/api/modules/entities";
import MaterialsModule from "~/api/modules/materials";
import ProductsModule from "~/api/modules/products";
import ReviewsModule from "~/api/modules/reviews";
import UserModule from "~/api/modules/user";
import CategoriesModule from "~/api/modules/categories";
import EventsModule from "~/api/modules/events";
import BlogModule from "~/api/modules/blog";
import SearchModule from "~/api/modules/search";
import { CACHE_API_ENDPOINTS } from "~/configs";

interface IApiInstance {
  general: GeneralModule;
  pages: PagesModule;
  blocks: BlocksModule;
  wishlist: WishlistModule;
  cart: CartModule;
  auth: AuthModule;
  catalog: CatalogModule;
  orders: OrdersModule;
  collections: CollectionsModule;
  entities: EntitiesModule;
  materials: MaterialsModule;
  products: ProductsModule;
  reviews: ReviewsModule;
  user: UserModule;
  categories: CategoriesModule;
  events: EventsModule;
  blog: BlogModule;
  search: SearchModule;
}

const fatalErrors = [500, 403, 404];

export default defineNuxtPlugin(({ $i18n }) => {
  const config = useRuntimeConfig();
  const generalStore = useGeneralStore();
  const nuxtApp = useNuxtApp();
  const localePath = useLocalePathPolyfill();

  const currentLanguageCode = ($i18n as Composer).locale.value;

  function updateCacheVersionParam(
    requestData: FetchContext,
    generalStore: ReturnType<typeof useGeneralStore>,
  ) {
    type CacheApiEndpointsKeys = keyof typeof CACHE_API_ENDPOINTS;
    const slug = (requestData.request as string).split(
      "/",
    )?.[1] as CacheApiEndpointsKeys;

    const isMethodGet =
      requestData.options?.method?.toLowerCase() === "get" ||
      !requestData.options?.method;
    if (
      isMethodGet &&
      slug &&
      Object.keys(CACHE_API_ENDPOINTS).includes(slug)
    ) {
      const param = CACHE_API_ENDPOINTS?.[slug];
      let params = {};
      if (requestData?.options?.params) {
        params = {
          ...requestData.options.params,
        };
      }
      requestData.options.params = {
        ...params,
        cv: generalStore.cacheVersions?.[param] || undefined,
      };
    }
  }

  function setRequestHeaders(
    requestData: FetchContext,
    generalStore: ReturnType<typeof useGeneralStore>,
  ) {
    const headers: { [key: string]: any } = {};
    let token = "";
    if (generalStore.getToken) {
      token = generalStore.getToken;
    }

    if (token) {
      headers.authorization = `Bearer ${token}`;
    }

    // const currentLanguageCode = "en";
    const defaultLangCode = (generalStore.languages || []).find(
      (lang) => lang.is_default,
    )?.slug;

    let lang = currentLanguageCode;

    if (defaultLangCode && defaultLangCode !== currentLanguageCode) {
      lang = `${currentLanguageCode},${defaultLangCode}`;
    }
    headers.lang = lang;
    requestData.options.headers = headers;
  }

  const fetchOptions: FetchOptions = {
    baseURL: config.public.apiBase as string,
    onRequest(requestData) {
      updateCacheVersionParam(requestData, generalStore);
      setRequestHeaders(requestData, generalStore);
    },
    onResponseError(context) {
      if (
        !context?.options?.skipErrors?.includes(context.response.status) &&
        fatalErrors.includes(context.response.status)
      ) {
        nuxtApp.runWithContext(() => {
          if (context.response.status === 401) {
            generalStore.setUserInfo(null, false);
            return navigateTo(localePath("/"));
          }
          return showError({
            statusCode: context.response.status,
            statusMessage:
              context.response?._data?.errors?.[0]?.message || "Error",
            fatal: true,
          });
        });
      }
    },
    onResponse(context) {
      const currentTranslationId =
        generalStore.getCurrentLanguage(currentLanguageCode as string)?.id ||
        generalStore.getDefaultLanguage?.id;

      context.response._data = {
        ...addCurrentTranslation(
          context.response._data,
          currentTranslationId as number,
          generalStore.getDefaultLanguage?.id as number,
        ),
        fetchedAt: new Date(),
      };
      // return context.response._data
    },
  };

  const apiFetcher = $fetch.create(fetchOptions);

  const modules: IApiInstance = {
    general: new GeneralModule(apiFetcher),
    pages: new PagesModule(apiFetcher),
    blocks: new BlocksModule(apiFetcher),
    wishlist: new WishlistModule(apiFetcher),
    cart: new CartModule(apiFetcher),
    auth: new AuthModule(apiFetcher),
    catalog: new CatalogModule(apiFetcher),
    orders: new OrdersModule(apiFetcher),
    collections: new CollectionsModule(apiFetcher),
    entities: new EntitiesModule(apiFetcher),
    materials: new MaterialsModule(apiFetcher),
    products: new ProductsModule(apiFetcher),
    reviews: new ReviewsModule(apiFetcher),
    user: new UserModule(apiFetcher),
    categories: new CategoriesModule(apiFetcher),
    events: new EventsModule(apiFetcher),
    blog: new BlogModule(apiFetcher),
    search: new SearchModule(apiFetcher),
  };

  return {
    provide: {
      api: modules,
    },
  };
});
