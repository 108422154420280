import FetchFactory from "../factory";
import {
  ForgotForm,
  PasswordForm,
  RecoveryPasswordResponse,
  SignUpData,
  VerifyEmailResponse,
} from "@/types/api/auth.types";
import { AddressData, AddressResponse } from "@/types/api/cart.types";
import { UserResponse } from "~/types/api/user.types";
import { UserSubscriptionResponse } from "~/types/api/user-subscriptions.types";

class UserModule extends FetchFactory {
  /**
   * Recovery password
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/#api-User-PatchApiV1UsersRecoverPassword
   * @param data
   * @returns Promise
   */
  recoveryPasswordRequest(data: ForgotForm) {
    return this.fetch<RecoveryPasswordResponse>(
      "PATCH",
      "/users/recover-password",
      {
        body: data,
      },
    );
  }

  /**
   * Change password by recovery token
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/#api-User-PatchApiV1UsersChangePassword
   * @param data
   * @returns Promise
   */
  changePasswordByRecoveryToken(
    data: PasswordForm & { password_recovery_token: string },
  ) {
    return this.fetch("PATCH", "/users/change-password", {
      body: data,
    });
  }

  /**
   * Verify Email
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/#api-User-PostApiV1UsersVerifyEmail
   * @param data
   * @returns Promise
   */
  verifyEmail(data: SignUpData) {
    return this.fetch<VerifyEmailResponse>("POST", "/users/verify-email", {
      body: data,
    });
  }

  /**
   * Create a new shipping address for a session user
   *
   * @link https://dev-opr.diamondnexus.com/apidoc/#api-User-PostApiV1UsersAddressesShipping
   * @param data
   * @returns Promise
   */
  setUserShippingAddress(data: AddressData) {
    return this.fetch("POST", "/users/addresses/shipping", {
      body: data,
    });
  }

  /**
   * Get shipping address list
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/#api-User-GetApiV1UsersAddressesShipping
   * @returns Promise
   */
  getUserShippingAddressList() {
    return this.fetch<AddressResponse>("GET", "/users/addresses/shipping", {
      key: "getUserShippingAddressList",
    });
  }

  /**
   * Update a shipping address of a session user
   *
   * @link https://dev-opr.diamondnexus.com/apidoc/#api-User-PutApiV1UsersAddressesShippingId
   * @param data
   * @param id
   * @returns Promise
   */
  updateUserShippingAddress(data: AddressData, id: number) {
    return this.fetch("PUT", `/users/addresses/shipping/${id}`, {
      body: data,
    });
  }

  /**
   * Set Default Shipping address
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/#api-User-GetApiV1UsersAddressesShipping
   * @returns Promise
   */
  setDefaultShippingAddress(id: number, params: any) {
    return this.fetch("PATCH", `/users/addresses/shipping/${id}`, {
      body: params,
    });
  }

  /**
   * get user subscription
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/#api-User-GetApiV1UsersAddressesShipping
   * @returns Promise
   */
  getUserSubscription() {
    return this.fetch<UserSubscriptionResponse>(
      "GET",
      `/users/subscriptions`,
      {},
    );
  }

  /**
   * get user subscription
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/#api-User-GetApiV1UsersAddressesShipping
   * @returns Promise
   */
  updateUserSubscription(data: number[]) {
    return this.fetch<UserSubscriptionResponse>(
      "PATCH",
      `/users/subscriptions`,
      {
        body: data,
        key: "updateUserSubscription",
      },
    );
  }

  /**
   * Update session user
   *
   * @link https://dev-opr.diamondnexus.com/apidoc/#api-User-PutApiV1Users
   * @param data
   * @returns Promise
   */
  updateUser(data: any) {
    return this.fetch<UserResponse>("PUT", "/users", {
      body: data,
    });
  }

  /**
   * Delete an user shipping address
   *
   * @link https://dev-opr.diamondnexus.com/apidoc/#api-User-DeleteApiV1UsersAddressesShippingId
   * @returns Promise
   * @param id
   */
  deleteUserShippingAddress(id: number) {
    return this.fetch("DELETE", `/users/addresses/shipping/${id}`, {
      key: "deleteUserShippingAddress",
    });
  }
}

export default UserModule;
