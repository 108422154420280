import { UseFetchOptions } from "#app";
import { FetchContext } from "ofetch";
import { useGeneralStore } from "@/stores/general";
import { addCurrentTranslation } from "@/utils";
import { CACHE_API_ENDPOINTS } from "@/configs";

const fatalErrors = [500, 403, 404];

function updateCacheVersionParam(
  requestData: FetchContext,
  generalStore: ReturnType<typeof useGeneralStore>,
) {
  type CacheApiEndpointsKeys = keyof typeof CACHE_API_ENDPOINTS;
  const slug = (requestData.request as string).split(
    "/",
  )?.[1] as CacheApiEndpointsKeys;

  const isMethodGet =
    requestData.options?.method?.toLowerCase() === "get" ||
    !requestData.options?.method;
  if (isMethodGet && slug && Object.keys(CACHE_API_ENDPOINTS).includes(slug)) {
    const param = CACHE_API_ENDPOINTS?.[slug];
    let params = {};
    if (requestData?.options?.params) {
      params = {
        ...requestData.options.params,
      };
    }
    requestData.options.params = {
      ...params,
      cv: generalStore.cacheVersions?.[param] || undefined,
    };
  }
}

export function useFetchInstance<DataT>(
  url: string | (() => string),
  options?: UseFetchOptions<DataT>,
  configs?: { skipErrors?: number[] },
) {
  // const { $i18n } = useNuxtApp();
  const nuxtApp = useNuxtApp();
  const config = useRuntimeConfig();
  const generalStore = useGeneralStore();
  const router = useRouter();
  const route = useRoute();

  const headers: { [key: string]: any } = {};
  let token = "";
  if (generalStore.getToken) {
    // if get 500 error hide it
    // headers.Authorization = `Bearer ${generalStore.getToken}`;
    token = generalStore.getToken;
  }

  // const userInfoData = useCookie<any>("user_info"); //  500 error - fix
  // const token = userInfoData.value?.access_token?.token;
  if (token) {
    headers.authorization = `Bearer ${token}`;
  }

  // const currentLanguageCode = "en";
  const currentLanguageCode = nuxtApp.$i18n.locale.value;
  const defaultLangCode = (generalStore.languages || []).find(
    (lang) => lang.is_default,
  )?.slug;

  let lang = currentLanguageCode;

  if (defaultLangCode && defaultLangCode !== currentLanguageCode) {
    lang = `${currentLanguageCode},${defaultLangCode}`;
  }
  headers.lang = lang;

  const localePath = useLocalePathPolyfill();

  return useFetch(url, {
    // timeout: 59000,
    // timeout: 50000,
    baseURL: config.public.apiBase,
    ...options,
    headers,
    transform: (val): DataT => {
      const currentTranslationId =
        generalStore.getCurrentLanguage(currentLanguageCode as string)?.id ||
        generalStore.getDefaultLanguage?.id;

      return addCurrentTranslation(
        val,
        currentTranslationId as number,
        generalStore.getDefaultLanguage?.id as number,
      );
    },
    onRequest(requestData) {
      updateCacheVersionParam(requestData, generalStore);
    },
    onResponseError({ response }) {
      if (response?.status === 401) {
        nuxtApp.runWithContext(() => {
          generalStore.setUserInfo(null);
          router.replace(localePath("/"));
        });
      } else if (
        !configs?.skipErrors?.includes(response?.status) &&
        fatalErrors.includes(response?.status)
      ) {
        if (route.path === "/engagement-ring-settings/review") {
          if (route.query?.startWith === "diamond") {
            nuxtApp.runWithContext(() => {
              router.replace(localePath("/diamonds-settings"));
            });
          } else if (route.query?.startWith === "product") {
            nuxtApp.runWithContext(() => {
              router.replace(localePath("/engagement-ring-settings"));
            });
          }
        } else {
          nuxtApp.runWithContext(() => {
            return showError({
              statusCode: response.status,
              statusMessage: response?._data?.errors?.[0]?.message || "Error",
              fatal: true,
            });
          });
        }
      }
    },
  });
}

export * from "./_test";
export * from "./_general";
export * from "./_blocks";
export * from "./_pages";
export * from "./_collections";
export * from "./_catalog";
export * from "./_wishlist";
export * from "./_products";
export * from "./_categories";
export * from "./_entities";
export * from "./_materials";
export * from "./_cart";
export * from "./_auth";
export * from "./_user";
export * from "./_orders";
export * from "./_reviews";
