import { ITranslation } from "~/types/components.types";

export interface PaymentMethod {
  id: number;
  payment_gateway_id: number;
  status: number;
  title: string;
  created_at: string;
  updated_at: string;
}

export interface QuestionParams {
  user_id: null | number;
  first_name: string;
  last_name: string;
  slug: string;
  question: string;
  email: string;
  subject: string;
  phone: string;
}

export interface PaymentGateway {
  id: number;
  code: number;
  status: number;
  title: string;
  description: string | null;
  created_at: string;
  updated_at: string;
  payment_methods: PaymentMethod[];
}

export interface PaymentGatewayResponse {
  data: PaymentGateway[];
  links: any;
  meta: any;
}

export interface LinksData {
  first: string;
  last: string;
  prev: any;
  next: any;
}

export interface LinkData {
  url?: string;
  label: string;
  active: boolean;
}

export interface MetaData {
  current_page: number;
  from: number;
  last_page: number;
  links: LinkData[];
  path: string;
  per_page: number;
  to: number;
  total: number;
}

export interface PaymentMethodData {
  id: number;
  payment_gateway_id: number;
  status: number;
  title: string;
  code?: string;
  created_at: string;
  updated_at: string;
  express: boolean;
}

export interface PaymentMethodResponse {
  data: PaymentMethodData[];
  links: LinksData;
  meta: MetaData;
}

export interface LanguageI {
  id: number;
  is_default: boolean;
  name: string;
  slug: string;
}

export interface LanguagesResponse {
  data: LanguageI[];
  meta: any[];
}

export interface ImpactParams {
  orderId: string | number;
  irClickId: string;
}
export type ImpactResponse = null | undefined;

export interface IPopupsParams {
  url: string;
}

export interface IPopupTranslation {
  language_id: ITranslation["language_id"];
  name: string;
  description: string;
  content: string;
}

export enum PopupFrequencyEnum {
  Once = 1,
  EachPageRefresh = 2,
  EachPageView = 3,
  PerDay = 4,
}
export interface IPopup {
  id: string | number;
  frequency: PopupFrequencyEnum;
  translations: IPopupTranslation[];
  priority: number;
  currentTranslation: IPopupTranslation;
}
export interface IPopupsResponse {
  data: IPopup[];
}

export enum DiscountCampaignStatus {
  Inactive = 0,
  Active = 1,
}

export enum DiscountCampaignDiscountType {
  SignUpDiscount = 1,
  BirthdayDiscount = 2,
  HolidayDiscount = 4,
  OrderAmountDiscount = 3,
  OtherDiscount = 5,
}
export interface IDiscountCampaignCurrentDiscount {
  id: number;
  status: number;
  code: string;
  amount: string;
  amount_type: number;
  min_amount: null;
  min_amount_type: number;
  start_at: string;
  end_at: null;
  max_used: null;
  current_used: number;
  once_per_user: boolean;
  once_per_order: boolean;
  max_free_shipping_amount: string;
  created_by: null;
  updated_by: null;
  deleted_by: null;
  created_at: string;
  updated_at: string;
  deleted_at: null;
  is_not_case_sensitive: boolean;
  offer_id: null;
  campaign_id: number;
}

interface IDiscountCampaignTranslation {
  id: number;
  discount_campaigns_id: number;
  language_id: ITranslation["language_id"];
  top_bar_text: string;
  popup_text: string;
  created_at: string;
  updated_at: string;
  created_by: number;
  updated_by: number;
}

export interface IDiscountCampaignPage {
  id: number;
  status: number;
  discount_campaigns_id: number;
  page_link: string;
  created_at: string;
  updated_at: string;
  created_by: number;
  updated_by: number;
}

export interface IDiscountCampaign {
  id: number;
  status: DiscountCampaignStatus;
  type: DiscountCampaignDiscountType;
  amount_type: string | number;
  name: string;
  amount: string;
  discount_value: string;
  order_value: string | number | null;
  is_top_bar_text: boolean;
  is_popup: boolean;
  frequency: PopupFrequencyEnum;
  holiday_date: string | null;
  discount_date: string | null;
  start_at: string | null;
  end_at: string | null;
  created_at: string;
  updated_at: string;
  created_by: number;
  updated_by: number;
  popup_link: string;
  translations: IDiscountCampaignTranslation[];
  currentTranslation: IDiscountCampaignTranslation;
  current_discount: IDiscountCampaignCurrentDiscount;
  pages: IDiscountCampaignPage[];
}

export interface ITopBannerDiscountCampaign {
  discount_campaign_id: IDiscountCampaign["id"];
  top_bar_text: IDiscountCampaign["currentTranslation"]["top_bar_text"];
}

export interface IDiscountCampaignPopup {
  discount_campaign_id: IDiscountCampaign["id"];
  id: string;
  frequency: IDiscountCampaign["frequency"];
  currentTranslation: {
    content: IDiscountCampaign["currentTranslation"]["popup_text"];
  };
  pages: IDiscountCampaign["pages"];
}
export interface IDiscountCampaignsData {
  topBanner: ITopBannerDiscountCampaign | null;
  popups: IDiscountCampaignPopup[];
}

export interface IDiscountCampaignResponse {
  data: IDiscountCampaign[];
  meta: any[];
}
