import FetchFactory from "../factory";
import { WishlistResponse } from "~/types/api/wishlist.types";

class WishlistModule extends FetchFactory {
  /**
   * Add to wishlist
   *
   * @link https://dev-opr.diamondnexus.com/apidoc/#api-Wishlist-PostApiV1Wishlists
   * @param data
   * @returns Promise
   */
  addItem(data: any) {
    return this.fetch<WishlistResponse>("POST", "/wishlists", {
      body: data,
    });
  }

  /**
   * Get or create wishlist
   *
   * @link https://dev-opr.diamondnexus.com/apidoc/#api-Wishlist-GetApiV1Wishlists
   * @returns Promise
   * @param uniqueId
   */
  getWishList(uniqueId?: string | null) {
    const params: { unique_id?: string } = {};
    if (uniqueId) {
      params.unique_id = uniqueId;
    }
    return this.fetch<WishlistResponse>("GET", "/wishlists", {
      params,
      skipErrors: [404, 500],
    });
  }
}

export default WishlistModule;
