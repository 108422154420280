import { generalApi } from "@/api";
import { useGeneralStore } from "~/stores/general";
import { IntegrationsType } from "~/types/api/bootstrap.types";
export const useIrClickId = () => {
  const route = useRoute();
  const generalStore = useGeneralStore();

  const { irclickid } = useCookiesService();

  function setIrClickId() {
    if (!generalStore.isIntegrationEnabled(IntegrationsType.TYPE_IMPACT)) {
      return;
    }
    if (route.query?.irclickid) {
      irclickid.value = route.query.irclickid as string;
    }
  }
  function removeIrClickId() {
    irclickid.value = null;
  }
  async function sendIrClickId(orderId: string | number | undefined) {
    if (
      !irclickid.value ||
      !orderId ||
      !generalStore.isIntegrationEnabled(IntegrationsType.TYPE_IMPACT)
    ) {
      return;
    }
    const res = await generalApi.sendIrclickidImpact({
      orderId: String(orderId),
      irClickId: irclickid.value,
    });
    if (res?.error?.value) {
      console.log("error - ", res.error.value?.response);
    }
    return res;
  }
  return {
    setIrClickId,
    removeIrClickId,
    sendIrClickId,
  };
};
