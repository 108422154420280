import FetchFactory from "../factory";
import { EventsParams } from "~/types/api/events.types";

class EventsModule extends FetchFactory {
  /**
   * Send front event to statistic collector
   *
   * @link
   * @returns Promise
   * @param payload
   * @param domain
   * @param apiKey
   */
  sendEventToSC(payload: EventsParams, domain: string, apiKey: string) {
    return this.fetch<EventsParams>("POST", `${domain}/stats`, {
      body: payload,
      params: {
        api_key: apiKey,
      },
      skipErrors: [400, 401, 403, 404, 500, 502, 503, 504],
      key: payload.event_id,
    });
  }

  /**
   * Send front event to back
   *
   * @link https://dev-opr.diamondnexus.com/public/apidoc/#api-Events
   * @returns Promise
   * @param payload
   */
  sendEventToBack(payload: EventsParams) {
    return this.fetch<EventsParams>("POST", "/events/frontend-event", {
      body: payload,
      skipErrors: [400, 401, 403, 404, 500, 502, 503, 504],
      key: payload.event_id,
    });
  }
}

export default EventsModule;
