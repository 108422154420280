import type { RouteLocationRaw } from "vue-router";

export const useLocalePathPolyfill = () => {
  const router = useRouter();
  const localePath = useLocalePath();
  return (path: RouteLocationRaw) => {
    if (typeof path === "string" && path.startsWith("http")) {
      return path;
    }
    const route = router.resolve(path);
    return localePath(route.fullPath);
  };
};
