import FetchFactory from "../factory";
import { SearchResponse } from "~/types/api/search.types";

class SearchModule extends FetchFactory {
  /**
   * Get search suggestions
   * @link https://devapi-opr.diamondnexus.com/public/apidoc/#api-Search-GetApiV1ModulesSearchSuggestions
   * @returns Promise
   */

  getSearchSuggestions(query: { query?: string }): Promise<SearchResponse> {
    return this.fetch<SearchResponse>("GET", "modules/search/suggestions", {
      params: query,
    });
  }
}

export default SearchModule;
