import { SearchParameters } from "ofetch";
import { has as hasObjectProperty } from "lodash-es";
import { Media } from "@/types/media.types";
import { validFileExtensions } from "~/configs";

export function parseJson<T>(code: string): T | null {
  try {
    return JSON.parse(code);
  } catch (e) {
    return null;
  }
}

export type TreeItem<T> = T & {
  id: string | number | null;
  parent_id: string | number | null;
  children?: TreeItem<T>[];
};

export function toTree<T>(list: TreeItem<T>[]): TreeItem<T>[] {
  const arr = parseJson<TreeItem<T>[]>(JSON.stringify(list)) || [];
  const arrMap = new Map(arr.map((item) => [item.id, item]));
  const tree: TreeItem<T>[] = [];

  arr.forEach((item) => {
    if (item.parent_id) {
      const parentItem = arrMap.get(item.parent_id);

      if (parentItem) {
        if (parentItem.children) {
          parentItem.children.push(item);
        } else {
          parentItem.children = [item];
        }
      }
    } else {
      tree.push(item);
    }
  });

  return tree;
}

export function convertToUnit(str: number, unit?: string): string;
export function convertToUnit(
  str: string | number | null | undefined,
  unit?: string,
): string | undefined;
export function convertToUnit(
  str: string | number | null | undefined,
  unit = "px",
): string | undefined {
  if (str == null || str === "") {
    return undefined;
  } else if (isNaN(+str!)) {
    return String(str);
  } else if (!isFinite(+str!)) {
    return undefined;
  } else {
    return `${Number(str)}${unit}`;
  }
}

export function generateString(
  length = 5,
  chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ",
) {
  return Array(length)
    .fill("")
    .map(() => chars[Math.floor(Math.random() * chars.length)])
    .join("");
}

export function objectToQuery(object: SearchParameters) {
  return Object.entries(object).reduce((acc, [key, value]: [string, any]) => {
    if (isArray(value) && !isEmpty(value)) {
      acc[`${key}[]`] = value;
    } else if (!isArray(value)) {
      acc[key] = value;
    }
    return acc;
  }, {} as SearchParameters);
}

export function getImageByPosition(list: Media[], position: string) {
  return list?.find((el) => el.position === position);
}

export const DataURIToBlob = (dataURI: string) => {
  if (!dataURI) return;
  const splitDataURI = dataURI.split(",");
  if (!splitDataURI.length) {
    return;
  }
  const byteString = splitDataURI[0].includes("base64")
    ? atob(splitDataURI[1])
    : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
};

export function mergeArraysToChessOrder<T, D>(arr1: T[], arr2: D[]) {
  const min = Math.min(arr1.length, arr2.length);
  let i = 0;
  const result: Array<T | D> = [];

  while (i < min) {
    result.push(arr1[i], arr2[i]);
    ++i;
  }
  return result.concat(arr1.slice(min), arr2.slice(min));
}

export async function downloadURI(uri: string, name: string) {
  const file = await fetch(uri);
  const fileBlog = await file.blob();
  const fileURL = URL.createObjectURL(fileBlog);

  const anchor = document.createElement("a");
  anchor.href = fileURL;
  anchor.download = name;

  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);

  URL.revokeObjectURL(fileURL);
}

export function getAllowedExt(type: keyof typeof validFileExtensions) {
  return validFileExtensions[type].join(",");
}

export function isValidFileType(
  validateType: keyof typeof validFileExtensions,
  fileType: File["type"],
) {
  return validateType && validFileExtensions[validateType].includes(fileType);
}

export function checkIfFilesAreCorrectType(files?: File[]): boolean {
  return files
    ? files.every((file) => isValidFileType("image", file.type))
    : true;
}

export function countLines(element: HTMLElement | null) {
  if (!element) {
    return 0;
  }
  const computedStyles = window.getComputedStyle(element);
  const lineHeight = Number(computedStyles.lineHeight.replace("px", ""));
  const divHeight = element.offsetHeight;
  return divHeight / lineHeight;
}

export function metaTitleWithPage(
  title: string,
  page: string | null,
  t: (...args: any[]) => string,
) {
  if (page) {
    return `${title} - ${t("page")} ${page}`;
  }
  return title;
}

export function mergeObjects<T, U>(obj1: T, obj2: U): T & U;
export function mergeObjects(obj1: any, obj2: any): any {
  const mergedObj = JSON.parse(JSON.stringify(obj1)) as any;
  for (const key in obj2) {
    if (Object.hasOwn(obj2, key)) {
      if (isEmpty(obj1[key]) && !isNumber(obj1[key])) {
        mergedObj[key] = obj2[key];
      } else if (
        typeof mergedObj[key] === "object" &&
        typeof obj2[key] === "object"
      ) {
        mergedObj[key] = mergeObjects(mergedObj[key], obj2[key]);
      }
    }
  }
  return mergedObj;
}

interface ICurrentTranslation<T extends Record<string, any>> {
  currentTranslation?: T["translations"][number];
}
export function addCurrentTranslation<T>(
  data: T[],
  translationId: number,
  defaultTranslationId: number,
): T & { currentTranslation?: ICurrentTranslation<T & Record<string, any>> }[];
export function addCurrentTranslation<T>(
  data: T,
  translationId: number,
  defaultTranslationId: number,
): T & { currentTranslation?: ICurrentTranslation<T & Record<string, any>> };
export function addCurrentTranslation(
  data: any,
  translationId: number,
  defaultTranslationId: number,
): any {
  if (!translationId || !defaultTranslationId) {
    return data;
  }
  if (Array.isArray(data)) {
    return data.map((item) =>
      addCurrentTranslation(item, translationId, defaultTranslationId),
    ) as any;
  } else if (isObject(data)) {
    const newData: any = { ...data };

    if (Array.isArray(newData.translations)) {
      const currentTranslation = newData.translations.find(
        (translation: any) => translation.language_id === translationId,
      );
      const defaultTranslation = newData.translations.find(
        (translation: any) => translation.language_id === defaultTranslationId,
      );

      newData.currentTranslation = mergeObjects(
        currentTranslation || {},
        defaultTranslation || {},
      );
    }

    for (const key in newData) {
      if (Object.hasOwn(newData as object, key)) {
        newData[key] = addCurrentTranslation(
          newData[key],
          translationId,
          defaultTranslationId,
        );
      }
    }

    return newData;
  }
  return data;
}

export function extractContentInBrackets(str: string) {
  const regex = /\(([^)]+)\)/;
  const match = str.match(regex);

  if (match) {
    return [str.replace(match[0], ""), match[0]]; // The content including brackets
  } else {
    return [str]; // No content between brackets found
  }
}

export function removeHTMLTagFromString(str: string) {
  return str ? str.replace(/<[^>]*>/g, " ") : "";
}

/**
 * Checks if provided date is equal to today's date.
 *
 * @param {Date} date - The date to be compared with today's date.
 * @returns {boolean} Returns `true` if provided date is today's date, `false` otherwise.
 */
export function isToday(date: Date): boolean {
  const today = new Date();

  return today.toDateString() === date.toDateString();
}

/**
 * Get random item from array.
 *
 * @param {array} items - Array of items.
 * @returns {*} Returns random item from array
 */
export function getRandomArrayItem<T>(items: Array<T>): T | undefined {
  if (items?.length) {
    return items[Math.floor(Math.random() * items.length)];
  }
}

/**
 * Set custom property
 * @param {string} property
 * @param {string|number} value
 */
export function setCustomProperty(property: string, value: string | number) {
  if (!property) return;
  document?.documentElement?.style?.setProperty(property, value.toString());
}

/** Replace placeholder with value
 * @param {string} value
 * @param {function} callback
 * */
export function replacePlaceholder(
  value: string,
  callback: (placeholder: string) => string,
) {
  return value.replace(/\{([^}]+)\}/g, (match, variable: string) => {
    return callback(variable) || match;
  });
}

/**
 * Extract button ID from URL.
 *
 * @param {string} url - URL containing the button ID.
 * @returns {string} Extracted button ID or an empty string if not found.
 */
export function extractButtonId(url: string): string {
  const match = url.match(/btn-id-([^?&]+)/);
  return match ? match[1] : "";
}

export type ErrorElement = {
  code: number;
  message: string;
  property: string;
};

export function responseErrorToObjectErrors(
  errors: ErrorElement[] | undefined,
  initialForm?: Record<string, any>,
): Record<string, string> {
  if (!Array.isArray(errors) || !errors) return {};

  return errors.reduce(
    (acc, el: ErrorElement) => {
      if (initialForm) {
        if (hasObjectProperty(initialForm, el.property)) {
          acc[el.property] = el.message;
        }
      } else {
        acc[el.property] = el.message;
      }

      return acc;
    },
    {} as Record<string, string>,
  );
}

export function addScripts(str, to, pos) {
  // Create an element outside the document to parse the string with
  const div = document.createElement("div");

  const target = typeof to === "string" ? document[to] : to;

  // Parse the string
  div.innerHTML = str;

  // Copy those nodes to the real `head`, duplicating script elements so
  // they get processed
  let node = div.firstChild as HTMLElement;
  while (node) {
    const next = node.nextSibling as HTMLElement;
    if (node.tagName === "SCRIPT") {
      // Just appending this element wouldn't run it, we have to make a fresh copy
      const newNode = document.createElement("script");
      [...node.attributes].forEach((attr) => {
        newNode.setAttribute(attr.nodeName, attr.nodeValue || "");
      });
      while (node.firstChild) {
        // Note we have to clone these nodes
        newNode.appendChild(node.firstChild.cloneNode(true));
        node.removeChild(node.firstChild);
      }
      node = newNode;
    }
    if (pos === "start") {
      target.prepend(node.cloneNode(true));
    } else {
      target.append(node.cloneNode(true));
    }

    node = next;
  }
}

export function extractDataFromScriptTags(htmlString) {
  // Create a new DOMParser
  const parser = new DOMParser();
  // Parse the HTML string
  const doc = parser.parseFromString(htmlString, "text/html");
  // Select all script elements
  const scriptElements = doc.querySelectorAll("script");
  const scriptStrings = Array.from(scriptElements).reduce(
    (acc: string[], script: HTMLScriptElement) => {
      acc.push(script.outerHTML);
      return acc;
    },
    [],
  );
  return scriptStrings;
}
