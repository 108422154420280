import { addMethod, string } from "yup";
import { useGeneralStore } from "@/stores/general";

export default defineNuxtPlugin(() => {
  const { getFilter, clientAppInit } = useGeneralStore();

  addMethod(string, "email", function () {
    return this.matches(
      /^\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b$/,
      {
        name: "email",
        message: (path) => `${path.label} must be a valid email`,
        excludeEmptyString: true,
      },
    );
  });

  const route = useRoute();
  const { cartUniqueId } = useCookiesService();

  let getCartByCartID = false; // TODO refactor it
  const cartUniqueIdRoutes = ["checkout", "cart"];
  if (
    cartUniqueIdRoutes.some((path) => route.path.split("/").includes(path)) &&
    route.params.id &&
    route.params.id !== cartUniqueId.value
  ) {
    cartUniqueId.value = route.params.id as string;
    getCartByCartID = true;
  }

  nextTick(async () => {
    await Promise.all([
      getFilter(),
      clientAppInit({ cartUniqueId, getCartByCartID }),
    ]);
  });
});
